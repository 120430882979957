<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: Contact Us Form
Description:
This component represents a multi-step "Contact Us" form, guiding users through a series of steps to gather
necessary information for contact or project inquiries. The form includes sections for personal information,
contact purpose, project status, budget, and project description. It features a progress bar to indicate the
user's progress and includes functionality for sending the collected information via email, along with attachments
if provided. The form also provides navigation between steps and handles user confirmation on exit.

How to use it:
<ContactUsHolder :name="'John Doe'" :email="'johndoe@example.com'" :company="'Acme Corp'" @closeForm="handleCloseForm" />
Pass optional props such as `name`, `email`, and `company` to pre-fill the initial form fields. The component emits a
`closeForm` event when the form is closed, which can be used to handle any additional closing logic.
-->
<template>
  <div class="contact-form-container">
    <div class="close-button-container">
      <img
        :src="$cdn('icons/icon_close.svg')"
        alt="Close button"
        class="close-button"
        @click="closeForm"
      >
    </div>
    <div class="main-contact-form">
      <div :class="`progress-bar ${progress === 6 ? 'progress-bar-complete' : ''}`">
        <div
          v-for="index in totalSteps"
          :key="index"
          :class="{'progress': true, 'progress-current': index <= progress}"
          @click="onClickProgress(index)"
        />
      </div>
      <ContactUsPageOne
        v-if="showPage === 1"
        :key="pageOneKey"
        :name="dataModel.name"
        :email="dataModel.email"
        :company="dataModel.company"
        :organization="dataModel.organization"
        :role="dataModel.role"
        :country-code="dataModel.contact_number_country_code"
        :phone-number="dataModel.contact_number"
        @submit="handlePageOneSubmit"
      />
      <ContactUsPageTwo
        v-if="showPage === 2"
        :contact-purpose="dataModel.contact_purpose"
        @submit="handlePageTwoSubmit"
      />
      <ContactUsPageThree
        v-if="showPage === 3"
        :project-status="dataModel.project_status"
        @submit="handlePageThreeSubmit"
      />
      <ContactUsPageFour
        v-if="showPage === 4"
        :project-budget="dataModel.budget"
        :project-date="dataModel.ideal_go_live_date"
        @submit="handlePageFourSubmit"
      />
      <ContactUsPageFive
        v-if="showPage === 5"
        :project-description="dataModel.project_description"
        :project-description2="dataModel.project_description2"
        :project-file="dataModel.attachment_file"
        :is-sending-email="isSendingEmail"
        @submit="handlePageFiveSubmit"
        @data-changes="handlePageFiveChanges"
      />
      <ContactUsThankYou v-if="showPage === 6" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, onBeforeUnmount } from 'vue';

import ContactUsPageOne from './ContactUsPageOne.vue';
import ContactUsPageTwo from './ContactUsPageTwo.vue';
import ContactUsPageThree from './ContactUsPageThree.vue';
import ContactUsPageFour from './ContactUsPageFour.vue';
import ContactUsPageFive from './ContactUsPageFive.vue';
import ContactUsThankYou from './ContactUsThankYou.vue';
import { constructEmailMessage, sendEmailWithAttachments } from '../../support/helper';

import { uuid } from 'vue3-uuid';

const emit = defineEmits(['closeForm']);

const totalSteps = 5; // Total number of steps in the progress bar
const progress = ref(1);
const showPage = ref(1);
const isSendingEmail = ref(false);

const pageOneKey = ref(uuid.v4());

const contactInfo = defineProps({
  name:{
    type: String,
    default: ''
  },
  email:{
    type: String,
    default: ''
  },
  company:{
    type: String,
    default: ''
  }
});

const onClickProgress = (index: number) => {
  if (index <= progress.value) {
    showPage.value = index;
    progress.value = index;
  }
};

const dataModel = {
  name: '',
  email: '',
  company: 'Please select',
  organization: '',
  role: '',
  contact_number_country_code: '',
  contact_number: '',
  contact_purpose: '',
  project_status: [],
  budget: '',
  ideal_go_live_date: undefined,
  project_description: '',
  project_description2: '',
  attachment_file: undefined,
};

let previousUrl = '';  // Declare a variable to store the previous URL
function closeTheFormWithUrlValidation(){
  if (previousUrl.includes('/contact-us')) {
    window.location.href = '/';
  }else{
    closeModal();
    emit('closeForm');
  }
}

const closeForm = () => {
  if (dataModel.name.length > 0) {
    // Using confirm to ask the user if they want to close the form
    const userConfirmed = confirm('All data will be lost. Are you sure you want to exit the form?');
    if (userConfirmed) {
      // User clicked "Yes", proceed to emit the close event
      closeTheFormWithUrlValidation();
    }
  } else {
    closeTheFormWithUrlValidation();
  }
};

let scrollPosition = 0;

function openModal() {
  scrollPosition = window.pageYOffset;  // Save the scroll position


  setTimeout(() => {
    document.body.style.overflow = 'hidden';
    // document.body.style.position = 'fixed';  // Optional: for better handling on iOS devices
    document.body.style.top = `-${scrollPosition}px`;
    // Save the current URL before opening the modal
    previousUrl = window.location.href;

    // Change the URL to '/contact-us' without reloading the page
    let newUrl = '/contact-us';
    if (newUrl.includes('#')) {
      newUrl = newUrl.split('#')[0];
    }
    history.pushState({ previousUrl }, '', newUrl);

  },100);
}

function closeModal() {
  setTimeout(() => {
    document.body.style.overflow = 'auto';
    // document.body.style.position = '';
    window.scrollTo(0, scrollPosition);  // Restore the scroll position

    // Restore the previous URL after closing the modal
    if (previousUrl.includes('#')) {
      previousUrl = previousUrl.split('#')[0];
    }
    history.pushState(null, '', previousUrl);  // Set back to the previous URL
  },100);
}


function handlePageOneSubmit(payload: any){
  dataModel.name = payload.name;
  dataModel.email = payload.email;
  dataModel.company = payload.company;
  dataModel.organization = payload.organization;
  dataModel.role = payload.role;
  dataModel.contact_number_country_code = payload.countryCode;
  dataModel.contact_number = payload.phoneNumber;
  showPage.value = 2;
  progress.value = 2;
}

function handlePageTwoSubmit(payload: any){
  dataModel.contact_purpose = payload.contact_purpose;
  showPage.value = 3;
  progress.value = 3;
}

function handlePageThreeSubmit(payload: any){
  dataModel.project_status = payload.project_status;
  showPage.value = 4;
  progress.value = 4;
}


function handlePageFourSubmit(payload: any){
  dataModel.budget = payload.project_budget;
  dataModel.ideal_go_live_date = payload.project_date;
  showPage.value = 5;
  progress.value = 5;
}

const budgets = ref([
  { value: 'US$ 10,000 - US$ 25,000' , category: 'Small' },
  { value: 'US$ 25,000 - US$ 50,000' , category: 'Medium' },
  { value: 'US$ 50,000 - US$ 100,000' , category: 'Large' },
  { value: 'US$ 100,000 or more' , category: 'Enterprise' },
]);

import { format } from 'date-fns';

function handlePageFiveSubmit(payload: any){
  const budgetCategory = budgets.value.find(budget => budget.category === dataModel.budget);
  if (budgetCategory) {
    dataModel.budget = budgetCategory.category + ': ' + budgetCategory.value;
  }

  if (dataModel.ideal_go_live_date) {
    dataModel.ideal_go_live_date = format(new Date(dataModel.ideal_go_live_date), 'dd/MM/yyyy');
  }

  const message = constructEmailMessage(dataModel);
  isSendingEmail.value = true;

  sendEmailWithAttachments(payload.recaptchaToken, message, dataModel.attachment_file, false, dataModel.organization).then(() => {
    // Email sent successfully, redirect the user
    showPage.value = 6;
    progress.value = 6;
    dataModel.name = '';
    dataModel.email = '';
    dataModel.company = '';
    dataModel.organization = '';
    dataModel.role = '';
    dataModel.contact_number_country_code = '+60';
    dataModel.contact_number = '';
    dataModel.contact_purpose = '';
    dataModel.project_status = [];
    dataModel.budget = '';
    dataModel.ideal_go_live_date = undefined;
    dataModel.project_description = '';
    dataModel.project_description2 = '';
    dataModel.attachment_file = undefined;
    isSendingEmail.value = false;
  })
    .catch((error) => {
      // Handle the error (show an error message to the user, log the error, etc.)
      console.log(error);
      alert('An error occurred while sending the email. Please try again later.');
      isSendingEmail.value = false;
    });;
}

function handlePageFiveChanges(payload: any){
  if (payload.project_description){
    dataModel.project_description = payload.project_description;
  }
  if (payload.project_description2){
    dataModel.project_description2 = payload.project_description2;
  }
  if (payload.project_file) {
    dataModel.attachment_file = payload.project_file;
  }
}

function  handleEscapeKey(event: KeyboardEvent) {
  if (event.key === 'Escape') {
    closeForm();
  }
};

onMounted(() => {
  openModal();
  if (process.env.APP_ENV === 'local'){
    dataModel.name = 'Eddy';
    dataModel.email = 'nCf2g@example.com';
    // dataModel.company = 'Startup';
    dataModel.organization = 'Acme Corp.';
    dataModel.role = 'CEO';
    dataModel.contact_number_country_code = '+60';
    dataModel.contact_number = '123456789';
    pageOneKey.value = uuid.v4();
  }

  if(contactInfo.name){
    dataModel.name = contactInfo.name;
    dataModel.email = contactInfo.email;
    dataModel.company = contactInfo.company;
    pageOneKey.value = uuid.v4();
  }


  document.addEventListener('keydown', handleEscapeKey);
});

onBeforeUnmount(() => {
  document.removeEventListener('keydown', handleEscapeKey);
});

function handleBackButton(){
  if (showPage.value > 1) {
    showPage.value -= 1;
    progress.value -= 1;
  }
}

</script>

<style scoped>
@keyframes enterAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.contact-form-container{
  display: flex;
  position: fixed;
  flex-direction: column;
  background-color: #FAFAFA;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  padding: 64px 64px 0 64px;
  align-items: center;
}

.main-contact-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 1024px;
  width: 100%;
  height: 100%;
  margin-top: 64px;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.close-button-container{
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.close-button{
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.progress-bar {
  display: flex;
  width: 100%;
  gap: 12px;
  margin-bottom: 32px;
}

.progress {
  width: 64px;
  height: 6px;
  border-radius: 14px;
  background-color: #DCDCDC;
  cursor: not-allowed;
}

.progress-current {
  background-color: #007AFF;
  cursor: pointer;
}

.progress-bar-complete{
  display: none;
}

@media screen and (max-width: 820px) {
  .contact-form-container{
    padding: 24px 24px 0 24px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .main-contact-form {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: stretch;
    width: 87vw;
    min-height: 0vh;
  }
  .progress {
    width: 58.8px;
    height: 6px;
    border-radius: 14px;
    background-color: #DCDCDC;
    cursor: not-allowed;
  }

  .progress-current {
    background-color: #007AFF;
    cursor: pointer;
  }
  .progress-bar {
    margin-left: 0px;
    margin-right: 0px;
  }
  .close-button{
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
}

</style>
