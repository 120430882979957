<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: Careers Page
Description:
This component displays a looping image carousel for the Careers Page, featuring dynamic content loaded from a JSON file.
The carousel supports both single and double image layouts per slide, using lazy loading and continuous autoplay for
an engaging visual presentation. The number of slides and spacing adjusts based on the screen size for a responsive user experience.

How to use it:
<CareerHeader />
The component does not require additional props as it automatically fetches the image data from a JSON file.
It can be included on the Careers page to provide an attractive visual element showcasing images related to the company culture or career opportunities.
-->

<template>
  <div class="main-container">
    <header class="main-title">
      Unbelievable, super cool, outrageous, and amazing.
    </header>
    <swiper
      v-if="imageBlocks.length > 0"
      :slides-per-view="slidesPerView"
      :space-between="pageBetween"
      :loop="true"
      :autoplay="{
        delay: 1,
        disableOnInteraction: false,
      }"
      :prevent-interaction-on-transition="true"
      :load-prev-next="true"
      :lazy-preload-prev-next="slidesPerView"
      :free-mode="true"
      :speed="8000"
      class="mySwiper"
    >
      <swiper-slide
        v-for="(block, index) in imageBlocks"
        :key="index"
      >
        <div
          v-if="block.type === 'single'"
          class="image-container"
        >
          <img
            :src="$cdn(block.images[0].src)"
            :alt="block.images[0].alt"
            class="image-large"
            loading="lazy"
          >
          <div class="swiper-lazy-preloader" />
        </div>
        <div
          v-if="block.type === 'double'"
          class="image-container double"
        >
          <img
            v-for="(image, i) in block.images"
            :key="`double-${index}-${i}`"
            :src="$cdn(image.src)"
            :alt="image.alt"
            class="image-small"
            loading="lazy"
          >
          <div class="swiper-lazy-preloader" />
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/parallax';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import SwiperCore from 'swiper';
// Import Swiper core and required modules
import { Autoplay, FreeMode, Navigation, Pagination } from 'swiper/modules';
import axios from 'axios';
import { cdn } from '../../support/cdn';

// Install Swiper modules
SwiperCore.use([Autoplay, FreeMode, Navigation, Pagination]);

const slidesPerView = ref(3);
const pageBetween = ref(34);

const imageBlocks = ref([]);


onMounted(() => {
  if (window.innerWidth <= 480) {
    slidesPerView.value = 1.5;
    pageBetween.value = 16;
  } else {
    slidesPerView.value = 3;
    pageBetween.value = 34;
  }

  axios.get(cdn('json/career-image-blocks.json')).then((response) => {
    imageBlocks.value = response.data;
  });
});

</script>

<style scoped>

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main-title{
  color: #242424;
  max-width: 1000px;
  font-family: 'Inter', sans-serif;
  font-size: 120px;
  font-style: normal;
  font-weight: 700;
  line-height: 120px;
  letter-spacing: -5px;
  margin: 0 48px;
  padding-bottom: 120px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  height: auto !important;
  align-items: stretch;
}

.image-container{
  display: flex;
  height: 100%;
  width: 100%;
}

.image-container.double {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 34px;
}

.image-large, .image-small {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-small{
  height: 50%;
}


@media screen and (max-width: 820px) {
  .main-title{
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1px;
    padding: 0;
    margin: 180px 80px 64px 80px;
  }

  .image-container.double {
    gap: 16px;
  }

  .mySwiper {
    height: 260px;
  }
}

@media screen and (max-width: 480px) {
  .main-title{
    margin: 180px 24px 64px 24px;
  }

}
</style>
