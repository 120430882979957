<template>
  <div>
    <blank-screen v-show="showForm" />
    <AppBarLight
      current-section="services"
      :class="`${showForm ? 'hidden' : ''}`"
      @get-in-touch="openForm"
    />
    <div class="spacing" />
    <ServiceHeader />
    <ServiceList
      v-if="serviceEntries.length > 0"
      :query="queryParams"
      :services="serviceEntries"
    />
    <Methodology />
    <CredoList
      v-if="credoEntries.length > 0"
      :services="credoEntries"
    />
    <ContactUsFooter
      @open-form="handleFormRequest"
    />
    <Footer
      @get-in-touch="openForm"
    />
    <transition name="fade">
      <ContactUsHolder
        v-if="showForm"
        :name="contactInfo.name"
        :email="contactInfo.email"
        :company="contactInfo.company"
        @close-form="closeForm"
      />
    </transition>
    <privacy-policy-banner />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import AppBarLight from '../components/general/AppBarLight.vue';
import ContactUsFooter from '../components/general/ContactUsFooter.vue';
import ServiceList from '../components/services/ServiceList.vue';
import CredoList from '../components/services/CredoList.vue';
import Methodology from '../components/services/Methodology.vue';
import ContactUsHolder from '../components/contact_us_form/ContactUsHolder.vue';
import ServiceHeader from '../components/services/ServiceHeader.vue';
import Footer from '../components/general/Footer.vue';
import axios from 'axios';
import { cdn } from '../support/cdn';

const showForm = ref(false);
const contactInfo = {
  name:'',
  email:'',
  company:''
};

const credoEntries = ref([]);
const serviceEntries = ref([]);

withDefaults(defineProps<{
  wordpressData?: any | null,
}>(), {
  wordpressData: null,
});

const handleFormRequest = (payload: any) => {
  contactInfo.name = payload.name;
  contactInfo.email = payload.email;
  contactInfo.company = payload.company;
  showForm.value = true;
};

const openForm = () => {
  showForm.value = true;
};

const closeForm = () => {
  showForm.value = false;
};

const queryParams = ref('');

onMounted(() => {
  const params = new URLSearchParams(window.location.search);
  queryParams.value = params.get('goto') || '';
  axios.get(cdn('json/services-value-credos.json')).then((response) => {
    credoEntries.value = response.data;
  });
  axios.get(cdn('json/services-value-list.json')).then((response) => {
    serviceEntries.value = response.data;
  });
});

</script>

<style scoped>
.spacing {
  height: 320px;
  width: 100%;
}
.hidden {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media screen and (max-width: 820px) {
  .spacing {
    height: 0;
  }
}
</style>
