<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: General Components
Description:
This component represents the footer of a website, featuring multiple sections: Services, Work, About, Contact, and locations.
Each section provides links and relevant information, making navigation easier for users.

How to use it:
Include this footer component at the bottom of your application's main layout to provide essential links and contact information.
The footer contains dynamic content, such as the list of works and blog items, fetched from the server.

Example usage:
<Footer @get-in-touch="handleGetInTouch" />
The `get-in-touch` event can be handled by the parent component to open a contact form or navigate the user to a relevant section.
-->

<template>
  <div class="site-footer">
    <div class="footer-widgets footer-widgets-top">
      <div class="footer-widget">
        <h4 class="footer-widget-title">
          SERVICES
        </h4>
        <ul>
          <li><a href="/services?goto=discovery">Discovery</a></li>
          <li><a href="/services?goto=strategy">Strategy</a></li>
          <li><a href="/services?goto=design">Design</a></li>
          <li><a href="/services?goto=development">Development</a></li>
          <li><a href="/services?goto=advisory">Advisory</a></li>
        </ul>
      </div>
      <div class="footer-widget">
        <h4 class="footer-widget-title">
          WORK
        </h4>
        <ul>
          <li
            v-for="(work, index) in works"
            :key="`&quot;project-${index}&quot;`"
          >
            <a :href="work.permalink">{{ work.title }}</a>
          </li>
        </ul>
      </div>
      <div class="footer-widget">
        <h4 class="footer-widget-title">
          ABOUT
        </h4>
        <ul>
          <li><a href="/about">About Alphapod</a></li>
          <li><a href="/careers">Careers</a></li>
        </ul>
      </div>

      <div class="footer-widget">
        <h4 class="footer-widget-title">
          CONTACT
        </h4>
        <ul>
          <li><a
            class="get-in-touch"
            @click="emit('get-in-touch')"
          >Get in touch</a></li>
          <li><a href="mailto:hello@alphapod.com">hello@alphapod.com</a></li>
        </ul>
      </div>
    </div>

    <hr class="footer-divider">

    <div class="footer-widgets footer-widgets-bottom">
      <div class="footer-widget">
        <h4 class="footer-widget-title">
          MALAYSIA
        </h4>
        <p>
          E-03-08, Sunway Geo Avenue 2<br>
          Jalan Lagoon Selatan, Bandar Sunway<br>
          47500 Subang Jaya<br>
          Selangor, Malaysia.
        </p>
      </div>
      <div
        v-show="showSingaporeAddress"
        class="footer-widget"
      >
        <h4 class="footer-widget-title">
          SINGAPORE
        </h4>
        <p>231 Mountbatten Road<br>Block C Level 3<br>Singapore 397999.</p>
      </div>
      <div
        v-show="!showSingaporeAddress"
        class="hidden-container"
      />
      <div class="hidden-container">
        <!-- TODO -->
      </div>
      <div class="footer-widget footer-widget-align-right">
        <p>© {{ dayjs().format('YYYY') }} Alphapod Sdn. Bhd. All Rights Reserved</p>
        <p><a href="/privacy-policy">Privacy Policy</a> | <a href="/cookie-policy">Cookie Policy</a></p>
      </div>
    </div>
  </div>

</template>

<script setup lang="ts">
import axios from 'axios';
import dayjs from 'dayjs';
import { onMounted, ref }  from 'vue';

interface BlogMeta {
  show_see_more: boolean,
  items: Blog[]
}

interface Blog {
  title: string,
  permalink: string
}

interface Work {
  title: string,
  permalink: string
}

const props = defineProps({
  worksData: {
    type: Array as () => Work[],
    default: undefined
  },
  blogsData: {
    type: Object as () => BlogMeta,
    default: undefined
  }
});

const emit = defineEmits(['get-in-touch']);

const works = ref<Work[]>([]);
const blogs = ref<BlogMeta>({show_see_more: false, items: []});

const showSingaporeAddress = ref(false);

function shortenString(text: string): string {
  return text.length > 40 ? text.slice(0, 40) + '...' : text;
}

function fetchWorks() {
  axios.get('/wp-json/api/v1/footer-works')
    .then(response => {
      works.value = response.data;
    })
    .catch(error => {
      // Handle error
      console.error('There was an error fetching the data:', error);
    });
}

function fetchBlogs() {
  axios.get('/wp-json/api/v1/footer-blogs')
    .then(response => {
      blogs.value = response.data;
    })
    .catch(error => {
      // Handle error
      console.error('There was an error fetching the data:', error);
    });
}

onMounted(() => {
  if (props.worksData) {
    works.value = props.worksData;
  }else{
    fetchWorks();
  }

  if (props.blogsData) {
    blogs.value = props.blogsData;
  }else{
    fetchBlogs();
  }

});

</script>

<style scoped>

.site-footer{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 32px;
  width: 100%;
}

.footer-divider,
.footer-widgets{
  max-width: 1248px;
}

.get-in-touch{
  cursor: pointer;
}

.footer-widgets-bottom{
    display: grid;
    grid-template-columns: 1fr 1fr 0.8fr 1.2fr;
    gap:20px;
}

.footer-widgets-top{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap:20px;
}

.footer-spacer{
  height: 40px;
}

/* Glow effect on hover */
.site-footer a:hover {
  text-decoration: underline;
  transition: text-decoration 0.3s ease-out;
}

@media screen and (max-width: 1024px) {
  .footer-widgets{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    gap: 32px;
  }

  .hidden-container {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .site-footer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 64px 24px;
    width: 100%;
  }
  .footer-widget-align-right,
  .footer-widgets{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
