<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: Services Page

Description:
The "Methodology" component showcases a detailed overview of the methodologies used by the company.
It features an interactive diagram and a swiper-based list of methodology steps, each with an accompanying image,
title, and description. Users can click on elements in the diagram or swipe between slides to explore each methodology
in more detail. The component provides keyboard navigation, zooming, and smooth scrolling for a dynamic user experience.

How to use it:
<Methodology
  :methods="methods"
/>
-->
<template>
  <div class="main-container">
    <div class="sub-container">
      <header class="methodology-main-title">
        Our methodology
      </header>
      <div class="methodology">
        <div :class="`diagram-container selected-${activeIndex}`">
          <div :class="`gradient-scrim ${activeIndex === null ? 'disable' : activeIndex > 2 ? '' : 'disable'}`" />
          <MethodologyDiagram
            :view-box="viewBox"
            :selected-button="selectedButton"
            @custom-event="handleCustomEvent"
          />
        </div>
        <div
          ref="target"
          :class="`method-container`"
        >
          <swiper
            v-if="methods.length > 0"
            :grab-cursor="true"
            :centered-slides="true"
            slides-per-view="auto"
            :pagination="false"
            :space-between="isMobile ? 16 : 32"
            class="mySwiper"
            @swiper="onSwiper"
          >
            <swiper-slide
              v-for="(block, index) in methods"
              :key="index"
              @click="centerSlide(index)"
            >
              <div
                :class="{
                  'item-container': true,
                  'active-slide': activeIndex === index,
                  'pulse-border' : index === 0 && !userInteracted
                }"
              >
                <img
                  :src="$cdn(block.src)"
                  :alt="block.alt"
                  class="image-item${{ index }}"
                >
                <h2>
                  {{ block.title }}
                </h2>
                <p>{{ block.description }}</p>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, Ref, onMounted, computed, onUnmounted, onBeforeUnmount } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/parallax';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import SwiperCore, { Swiper as SwiperType} from 'swiper';
// Import Swiper core and required modules
import { Autoplay, FreeMode, Navigation, Pagination } from 'swiper/modules';

// Install Swiper modules
SwiperCore.use([Autoplay, FreeMode, Navigation, Pagination]);

import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
// Ensure GSAP recognizes the ScrollToPlugin
gsap.registerPlugin(ScrollToPlugin);

const observer = ref<IntersectionObserver | null>(null);
const target = ref(null);

const mySwiperRef = ref<SwiperType>();
import MethodologyDiagram from './MethodologyDiagram.vue';
import { fadeInEffect } from '../../support/helper';
import axios from 'axios';
import { cdn } from '../../support/cdn';

const spaceBetween = ref(32);

const activeIndex: Ref<number | null> = ref(null);

const viewBox = ref('0 0 831 800');
const selectedButton = ref<any>(null);

const userInteracted = ref(false);

const methods = ref([]);

const onSwiper = (swiperInstance: SwiperType) => {
  mySwiperRef.value = swiperInstance;
};
const handleCustomEvent = (payload: string) => {
  if (payload == 'button1') {
    centerSlide(0);
  }else if(payload == 'button2') {
    centerSlide(1);
  }else if(payload == 'button3') {
    centerSlide(2);
  }else if(payload == 'button4') {
    centerSlide(4);
  }else if (payload == 'button5') {
    centerSlide(5);
  }else if (payload == 'button6') {
    centerSlide(7);
  }else if (payload == 'button7') {
    centerSlide(3);
  }else if (payload == 'button8') {
    centerSlide(8);
  }else if (payload == 'button9') {
    centerSlide(6);
  }
};

function handleKeyEvent(event: KeyboardEvent) {
  if (activeIndex.value === null) return;
  switch (event.key) {
    case 'ArrowLeft':
      if (activeIndex.value > 0) {
        centerSlide(activeIndex.value - 1);
      }
      break;
    case 'ArrowRight':
      if (activeIndex.value < 8) {
        centerSlide(activeIndex.value + 1);
      }
      break;
    case 'Escape':
      if (activeIndex.value !== null) {
        centerSlide(activeIndex.value); // This will reset activeIndex to null
      }
      break;
  }
}

onMounted(() => {
  window.addEventListener('keydown', handleKeyEvent);
  fadeInEffect('.methodology-main-title');
  axios.get(cdn('json/services-methodology.json')).then((response) => {
    methods.value = response.data;
  });

  // Create the IntersectionObserver to watch when the target element is in view
  observer.value = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        console.log('target is in view');
        const element = document.querySelector('.pulse-border');
        if (element) {
          element.classList.add('active');
        }
        // Stop observing once the animation has been triggered
        if (observer.value && target.value) {
          observer.value.unobserve(target.value);
        }
      }
    });
  });

  // Start observing the target element
  if (target.value) {
    observer.value.observe(target.value);
  }
});

onBeforeUnmount(() => {
  // Cleanup observer when the component is unmounted
  if (observer.value && target.value) {
    observer.value.unobserve(target.value);
    observer.value.disconnect();
  }
});

onUnmounted(() => {
  window.removeEventListener('keydown', handleKeyEvent);
  if (window.innerWidth <= 480) {
    spaceBetween.value = 8;
  }else{
    spaceBetween.value = 8;
  }
});

const isMobile = computed(() => {
  return window.innerWidth <= 480;
});

// Method to handle zoom in
function zoomIn() {
  if (!isMobile.value) {
    const methodContainer = document.querySelector('.method-container');
    if (methodContainer) {
    // Calculate the difference in height between the element and the viewport
      let offsetY = window.innerHeight - (methodContainer.clientHeight);
      // Scroll to the element with the calculated offset
      gsap.to(window, {duration: 0.5, scrollTo: {y: methodContainer, offsetY: offsetY}});
    }
  }
}

// Method to handle zoom out
function zoomOut() {
  if (window.innerWidth > 480) {
    const refPoint = document.querySelector('.methodology-main-title');
    if (refPoint){
      gsap.to(window, {duration: 0.5, scrollTo: {y: refPoint, offsetY: 50}});
    }
  }
}

const buttonList = ['button1','button2','button3','button7','button4','button5','button9','button6','button8'];

function centerSlide(index: number): void {
  userInteracted.value = true;
  if (activeIndex.value === index) {
    activeIndex.value = null;
    viewBox.value = '0 0 831 800';
    selectedButton.value = null;
    zoomOut();
    mySwiperRef.value?.slideTo(0);
  }else{
    activeIndex.value = index;
    selectedButton.value = buttonList[index];

    if (index == 0){
      //understand
      viewBox.value = '0 0 831 800';
    }else if (index == 1){
      //research
      viewBox.value = '0 0 831 800';
    }else if (index == 2){
      //ideate
      viewBox.value = '0 0 831 800';
    }else if (index == 3){
      //qualitative
      viewBox.value = '0 180 831 800';
    }else if (index == 4){
      //design
      viewBox.value = '0 180 831 800';
    }else if (index == 5){
      //develop
      viewBox.value = '0 300 831 800';
    }else if (index == 6){
      //launch
      viewBox.value = '0 300 831 800';
    }else if (index == 7){
      //measure
      viewBox.value = '0 300 831 800';
    }else if (index == 8){
      //quantitative
      viewBox.value = '0 180 831 800';
    }
    zoomIn();
    mySwiperRef.value?.slideTo(index);
  }

}

</script>
<style scoped>

@keyframes pulse-border {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 179, 0, 0.474);
  }
  70% {
    box-shadow: 0 0 15px 10px rgba(0, 123, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
  }
}

.pulse-border.active {
  animation: pulse-border 2s 5;
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

}

.sub-container{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  overflow: hidden;
}

.gradient-scrim {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 100;
}

.gradient-scrim.disable {
  display: none;
}

.methodology{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 1080px;
  width: 100%;
  margin-bottom: 80px;
}

.method-container {
  display: flex;
  width: 100%;
  height: fit-content;
  transition: bottom 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  z-index: 5;
}

.diagram-container {
  display: flex;
  justify-content: center;
  width: fit-content;
  min-width: 50%;
  height: 100%;
  z-index: 2;
}

.diagram-container.selected-null{
  transform: scale(1) translate(0, 0);
  transition: transform 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);
  overflow-x: hidden;
}
/* understand */
.diagram-container.selected-0{
  transform: scale(1.5) translate(200px, 250px);
  transition: transform 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);
  overflow-x: hidden;
}
/* research */
.diagram-container.selected-1{
  transform: scale(1.5) translate(0, 250px);
  transition: transform 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);
  overflow-x: hidden;
}
/* ideate */
.diagram-container.selected-2{
  transform: scale(1.5) translate(-200px, 250px);
  transition: transform 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);
  overflow-x: hidden;
}
/* qualitative research */
.diagram-container.selected-3{
  transform: scale(1.5) translate(-350px, 250px);
  transition: transform 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);
}
/* design */
.diagram-container.selected-4{
  transform: scale(1.5) translate(-350px, 180px);
  transition: transform 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);
}
/* develop */
.diagram-container.selected-5{
  transform: scale(1.5) translate(0, 150px);
  transition: transform 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);
}
/* launch */
.diagram-container.selected-6{
  transform: scale(1.5) translate(150px, 150px);
  transition: transform 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);
}
/* measure */
.diagram-container.selected-7{
  transform: scale(1.5) translate(250px, 260px);
  transition: transform 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);
}
/* quantitative research */
.diagram-container.selected-8{
  transform: scale(1.5) translate(280px, 250px);
  transition: transform 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.mySwiper{
  width: 100%;
}

.swiper-slide {
  width: 100% !important;
  max-width: 576px;
  height: 100% !important;
}

.methodology-main-title {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: -3px;
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 160px;
  margin-bottom: 87px;
  max-width: 1280px;
  width: 100%;
}

.item-container {
  display: flex;
  height: 400px;
  width: 100%;
  padding: 32px;
  margin: 16px 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex-shrink: 0;
  border: 1px solid #E6E6E6;
  background-color: #FFF;
}

.item-container img{
  width: 32px;
  height: auto;
}

.item-container h2{
  align-self: stretch;
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.25px;
}

.item-container p{
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.active-slide{
  background-color: #E6E6E6;
}

@media screen and (max-width: 1024px) {
  .methodology-main-title {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: -0.75px;
    text-align: start;
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 80px;
    margin-bottom: 50px;
    padding-left: 24px;
  }

  .diagram-container{
    max-width: 100%;
    padding: 0 10px;
  }

  .item-container{
    height: 380px;
    width: 100%;
  }

  .item-container img{
    width: 28px;
    height: auto;
  }

  .item-container h2{
    align-self: stretch;
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  .item-container p{
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
  }
}

@media screen and (max-width: 820px) {
  .methodology-main-title {
    padding-left: 80px;
  }

  .diagram-container{
    min-width: 0;
  }
}

@media screen and (max-width: 480px) {
  .main-container {
    overflow-x: hidden;
  }

  .item-container{
    height: 356px;
    aspect-ratio: none;
  }

  .methodology{
    height: 720px;
  }

  .swiper-slide {
    width: 80vw !important;
  }

  /* understand */
  .diagram-container.selected-0{
    transform: scale(1.5) translate(80px, 150px);
    transition: transform 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);
    overflow-x: hidden;
  }
  /* research */
  .diagram-container.selected-1{
    transform: scale(1.5) translate(0px, 175.78px);
    transition: transform 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);
    overflow-x: hidden;
  }
  /* ideate */
  .diagram-container.selected-2{
    transform: scale(1.5) translate(-140.63px, 150px);
    transition: transform 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);
    overflow-x: hidden;
  }
  /* qualitative research */
  .diagram-container.selected-3{
    transform: scale(1.5) translate(-150px, 120px);
    transition: transform 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);
  }
  /* design */
  .diagram-container.selected-4{
    transform: scale(1.5) translate(-150px, 80px);
    transition: transform 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);
  }
  /* develop */
  .diagram-container.selected-5{
    transform: scale(1.5) translate(0px, 90px);
    transition: transform 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);
  }
  /* launch */
  .diagram-container.selected-6{
    transform: scale(1.5) translate(60px, 80px);
    transition: transform 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);
  }
  /* measure */
  .diagram-container.selected-7{
    transform: scale(1.5) translate(80px, 90px);
    transition: transform 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);
  }
  /* quantitative research */
  .diagram-container.selected-8{
    transform: scale(1.5) translate(80px, 120px);
    transition: transform 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);
  }

}

</style>
