<template>
  <div style="background-color: black;">
    <blank-screen v-show="showForm" />
    <dna-appbar
      :class="`${showForm ? 'hidden' : ''}`"
      @get-in-touch="showForm = true"
      @on-click-menu="handleScroll"
    />
    <div

      class="spacing"
    />
    <dna-header id="target" />
    <dna-about id="section-1" />
    <dna-project-showcase
      v-if="projectEntries.length > 0"
      id="section-2"
      :key="projectKey"
      :works="projectEntries"
    />
    <dna-project-highlight
      v-if="projectEntries.length > 0"
      :key="projectKey"
      :works="projectEntries"
    />
    <dna-about-video id="section-3" />
    <dna-get-in-touch />

    <dna-contact-us-footer
      :key="paperplaneKey"
      ref="ref7"
      :show-paper-plane-animation="true"
      :works="projectEntries"
      @open-form="handleFormRequest"
      @end-animation="endPaperPlaneAnimation = true"
    />
    <Footer
      v-show="endPaperPlaneAnimation"
      @get-in-touch="openForm"
    />

    <!-- CONTACT US FORM -->
    <transition name="fade">
      <ContactUsHolder
        v-if="showForm"
        :name="contactInfo.name"
        :email="contactInfo.email"
        :company="contactInfo.company"
        @close-form="closeForm"
      />
    </transition>
    <privacy-policy-banner />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ContactUsHolder from '../components/contact_us_form/ContactUsHolder.vue';

import { uuid } from 'vue3-uuid';
import axios from 'axios';
import Footer from '../components/general/Footer.vue';
import { cdn } from '../support/cdn';

const showForm = ref(false);
const darkMode = ref(true);

const endPaperPlaneAnimation = ref(false);
const paperplaneKey = ref(uuid.v4());

const projectKey = ref(uuid.v4());
const showcaseItems = ref([]);

const projectEntries = ref([]);

const props = withDefaults(defineProps<{
  wordpressData?: any | null,
}>(), {
  wordpressData: null,
});

onMounted(() => {
  axios.get(cdn('json/dna-projects.json')).then((response) => {
    projectEntries.value = response.data;
  });
});

// const showcase = computed(() => {
//   return props.wordpressData?.works_items.filter((item:any) => item.is_not_featured === false);
// });

const contactInfo = {
  name:'',
  email:'',
  company:''
};

const handleFormRequest = (payload: any) => {
  contactInfo.name = payload.name;
  contactInfo.email = payload.email;
  contactInfo.company = payload.company;
  showForm.value = true;
};

const handleScroll = (index: string) => {
  const sectionId = `section-${index}`;
  const element = document.getElementById(sectionId);
  if (element) {
    const elementRect = element.getBoundingClientRect().top;
    const bodyRect = document.body.getBoundingClientRect().top;
    const offset = elementRect - bodyRect - 120;
    window.scrollTo({
      top: offset,
      behavior: 'smooth',
    });
  }
};


const openForm = () => {
  showForm.value = true;
};

const closeForm = () => {
  showForm.value = false;
};

function resetPaperplaneKey() {
  if (!endPaperPlaneAnimation.value) {
    paperplaneKey.value = uuid.v4();
  }
}

gsap.registerPlugin(ScrollTrigger);
onMounted(() => {
  getProjects();

  ScrollTrigger.create({
    trigger: '#target',
    start: 'top 16%',
    onEnter: () => {
      darkMode.value = false;
    },
    onLeaveBack: () => {
      darkMode.value = true;
    },
    markers: false,
  });
  if (props.wordpressData) {
    switch (props.wordpressData.query) {
      case 'contact-us':
        showForm.value = true;
        const urlWithoutParams = window.location.origin + window.location.pathname;
        window.history.pushState({}, document.title, urlWithoutParams);
        break;
      case 'job':
        const baseUrl = process.env.APP_URL;
        window.location.href = `${baseUrl}/careers/?goto=job-application`;
        break;
    }
  }
});


function getProjects() {
  axios.get('/wp-json/api/v1/dna-works').then(response => {
    showcaseItems.value = response.data;
    projectKey.value = uuid.v4();
  });
}

</script>

<style scoped>

.spacing {
  height: 200px;
  width: 100%;
}

.hidden {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media screen and (max-width: 820px) {
  .spacing {
    height: 60px;
  }
}
</style>
