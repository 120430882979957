<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: General Components
Description:
This component is a modal for managing cookie preferences. It allows users to choose which types of cookies they want to enable or disable,
such as performance, analytics, and social networking cookies. The essential cookies are mandatory and cannot be disabled. The component
is designed to work on both desktop and mobile devices, with responsive adjustments for the header layout and buttons.

How to use it:
<CookieModal
  @close-cookie-modal="handleCookiePreferences"
/>
Include this component to allow users to manage their cookie preferences. The `close-cookie-modal` event is emitted with the current state
of the cookies whenever the user either cancels or confirms their selection. Handle the event to save or act on the user's choices regarding cookie settings.
-->

<template>
  <div>
    <div class="bg-overlay" />
    <div class="main-container">
      <div class="sub-container">
        <header v-if="!isMobile">
          <h1>Manage cookies</h1>
          <img
            :src="$cdn('icons/icon_close_navbar.svg')"
            @click="closeCookieModal(null)"
          >
        </header>
        <header v-else>
          <img
            :src="$cdn('icons/icon_close.svg')"
            class="close-btn"
            @click="closeCookieModal(null)"
          >
          <h1>Manage cookies</h1>
        </header>
        <div class="cookie-body">
          <div
            v-for="(cookie, index) in cookies"
            :key="index"
            class="content"
          >
            <h2>{{ cookie.title }}</h2>
            <div class="sub-content">
              <p>{{ cookie.descriptions }}</p>
              <img
                v-if="cookie.isLocked"
                :src="$cdn('cookie/toggle_disable.svg')"
                class="lock-btn"
                @click="onTap(index)"
              >
              <img
                v-else-if="cookie.state"
                :src="$cdn('cookie/toggle_on.svg')"
                @click="onTap(index)"
              >
              <img
                v-else
                :src="$cdn('cookie/toggle_off.svg')"
                @click="onTap(index)"
              ></div>
          </div>
        </div>

        <footer>
          <button
            class="negative"
            @click="closeCookieModal(null)"
          >
            Cancel
          </button>
          <button
            class="positive"
            @click="closeCookieModal(cookies)"
          >
            Allow selection
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import { computed, ref } from 'vue';

const emit = defineEmits(['close-cookie-modal']);

const cookies = ref([
  {
    id: 0,
    title: 'Essential',
    state: true,
    isLocked: true,
    descriptions: 'These cookies are necessary to provide you with services available through our website.'
  },
  {
    id: 1,
    title: 'Performance',
    state: false,
    isLocked: false,
    descriptions: 'We use cookies to improve your experience. By using our site, you agree to our use of cookies.'
  },
  {
    id: 2,
    title: 'Analytics',
    state: false,
    isLocked: false,
    descriptions: 'These cookies analyse website usage, track marketing effectiveness, and customise our website for you.'
  },
  {
    id: 3,
    title: 'Social networking',
    state: false,
    isLocked: false,
    descriptions: 'These cookies allow you to share pages and content from our website to social networks.'
  },
]);

function onTap(index: number) {
  if (cookies.value[index].isLocked) {
    return;
  }
  cookies.value[index].state = !cookies.value[index].state;
}

const isMobile = computed(() => {
  return window.innerWidth <= 820;
});

function closeCookieModal(data:any) {
  emit('close-cookie-modal',data);
}

</script>

<style scoped>

.bg-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
}

.main-container{
  position: fixed;
  z-index: 9999;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  height: fit-content;
  width: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
}

.sub-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
  max-width: 500px;
}

header{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 24px 24px;
  border-bottom: 1px solid #dcdcdc;
}

footer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  gap: 16px;
  padding: 16px;
  border-top: 1px solid #dcdcdc;
}

.cookie-body{
  display: flex;
  flex-direction: column;
  gap: 32px;
}

h1 {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: -0.25px;
  text-align: left;
}

h2 {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
}

p {
  color: #969696;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.25px;
  text-align: left;
  flex-grow: initial;
}

img {
  cursor: pointer;
}

.lock-btn {
  cursor: default !important;
}

.content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 24px;
}

.sub-content{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 16px;
}

.positive {
  color: #fff;
  background-color: #007AFF;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  padding: 16px 24px;
  border-radius: 12px;
  height: 64px;
}

.negative {
  color: #969696;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  padding: 16px 24px;
  border-radius: 12px;
  border: 1px solid #dcdcdc;
  height: 64px;
}

@media screen and (max-width: 820px) {
  .main-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: none;
    border-radius: 0;
  }

  .sub-container{
    max-width: 100%;
    width: 100%;
    height: 100%;
    gap: 0;
  }

  header {
    margin-top: 0;
    padding: 24px;
    flex-direction: column;
    align-items: stretch;
    border-bottom: none;
    gap: 40px;
  }

  .close-btn{
    height: 28.8px;
    width: auto;
    align-self: flex-end;
  }

  .cookie-body {
    display: flex;
    flex-direction: column;
    padding-bottom: 52px;
    gap: 24px;
    overflow-y: scroll;
    flex-grow: 1;
  }

  h1 {
    font-family: 'Inter' sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
  }

  h2 {
    font-family: 'Inter' sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: left;

  }

  p {
    color: #969696;
    font-family: 'Inter' sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }

  .sub-content img {
    width: 58px;
  }

  footer {
    flex-direction: column-reverse;
    gap: 12px;
  }

  .positive {
    padding: 0 24px;
    text-align: center;
    height: 48px;
    width: 100%;
  }

  .negative {
    color: #242424;
    padding: 0 24px;
    text-align: center;
    height: 48px;
    width: 100%;
  }
}

</style>

