<template>
  <div class="main-container">

    <div class="title-container">
      <div>
        <h1 class="animate-section1">
          Case studies
        </h1>
        <p class="animate-section2">
          See how DNA has transformed creative ideas into impactful digital experiences, driving success for our clients across various industries.
        </p>
      </div>
    </div>

    <div
      :key="containerKey"
      class="sub-container"
    >
      <div class="column left-item">
        <div
          v-for="(item, index) in leftItem"
          :id="item.slug"
          :key="index"
          :enter="{
            scale: 2,
            transition: {
              delay: 1000,
            },
          }"
          class="grid-item"
        >
          <dna-project-showcase-card :item="item" />
        </div>
      </div>
      <div class="column right-item">
        <div
          v-for="(item, index) in rightItem"
          :id="item.slug"
          :key="index"
          :enter="{
            scale: 2,
            transition: {
              delay: 1000,
            },
          }"
          class="grid-item"
        >
          <dna-project-showcase-card :item="item" />
        </div>
      </div>
    </div>

  </div>
</template>

<script setup lang="ts">

import { onMounted, ref } from 'vue';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { uuid } from 'vue3-uuid';
import { DNAProject } from '../../support/model';
// import works from '../assets/dna-projects.json';
import { fadeInEffect } from '../../support/helper';

gsap.registerPlugin(ScrollTrigger);

const containerKey = ref(uuid.v4());

const leftItem: any[] = [];
const rightItem: any[] = [];

const gridItems = ref<DNAProject[]>([]);

const props = defineProps({
  works: {
    type: Array,
    required: true,
  }
});

onMounted(() => {
  gridItems.value = props.works;
  getProjects();
  fadeInEffect('.animate-section1');
  fadeInEffect('.animate-section2');
});

function getProjects() {
  if (gridItems.value.length > 0) {
    gridItems.value.filter((item: DNAProject) => item.image.available).forEach((item: any, index) => {
      if (index % 2 === 0) {
      // Even index, insert into leftItem
        leftItem.push(item);
      } else {
      // Odd index, insert into rightItem
        rightItem.push(item);
      }
    });
    containerKey.value = uuid.v4();
  }

}

</script>

<style scoped>

h1 {
  color: #FAFAFA;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Inter', sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: -3px;
  margin-bottom: 32px;
}

p {
  color: #FAFAFA;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 160% */
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease;
  background-color: black;
  padding: 160px 80px;
}

.container.light-background {
  background-color: white;
}

.title-container {
  display: flex;
  width: 100%;
  max-width: 1024px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 160px;
}

.sub-container {
  display: flex;
  width: 100%;
  max-width: 1280px;
  flex-direction: row;
  justify-content: space-between;
  gap: 80px;
}

.column {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 160px;
}

.right-item {
  margin-top: 400px;
}

.grid-item {
  overflow: hidden; /* Prevents the container from resizing */
  transition: transform 0.3s ease; /* Optional: Smooth transition for the container */
}

@media screen and (max-width: 820px) {

  h1 {
    font-size: 48px;
    line-height: 64px;
    letter-spacing: -2px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }

  .main-container {
    padding: 64px 24px;
  }

  .title-container {
    margin-bottom: 64px;
  }

  .sub-container {
    gap: 24px;
  }

  .column {
    gap: 64px;
  }

  .right-item {
    margin-top: 64px;
  }
}

</style>

