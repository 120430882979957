<template>
  <div>
    <blank-screen v-show="showForm" />
    <Transition name="fade">
      <AppBarDark
        v-if="darkMode"
        :class="`${showForm ? 'hidden' : ''}`"
        @get-in-touch="showForm = true"
      />
      <AppBarLight
        v-else
        :class="`${showForm ? 'hidden' : ''}`"
        :initial-expand="true"
        @get-in-touch="showForm = true"
      />
    </Transition>

    <HeroVue id="topEle" />
    <WelcomeMessage
      id="target"
      ref="ref1"
    />
    <BuilderMessage ref="ref2" />
    <BigSmallMessage
      v-if="!endBigSmallAnimation"
      ref="ref3"
      @end-animation="endBigSmallAnimation = true"
    />
    <div v-else>
      <BigSmallMessageNoAnimation ref="ref4" />
      <ProjectShowcase
        :key="workKey"
        ref="ref5"
        :dark-mode="true"
        :grid-items="showcase"
      />
      <ClientIconsDark
        :key="workKey"
        ref="ref6"
        :data="works"
        @show-paperplane="resetPaperplaneKey"
        @on-change-list="resetPaperplaneKey"
      />
      <ContactUsFooter
        :key="paperplaneKey"
        ref="ref7"
        :show-paper-plane-animation="true"
        :paper-plane-json="paperplane"
        :paper-plane-json-mobile="paperplaneMobile"
        @open-form="handleFormRequest"
        @end-animation="endPaperPlaneAnimation = true"
      />
      <Footer
        v-show="endPaperPlaneAnimation"
        :blogs-data="blogsFooter"
        :works-data="worksFooter"
        @get-in-touch="openForm"
      />
    </div>

    <!-- CONTACT US FORM -->
    <transition name="fade">
      <ContactUsHolder
        v-if="showForm"
        :name="contactInfo.name"
        :email="contactInfo.email"
        :company="contactInfo.company"
        @close-form="closeForm"
      />
    </transition>
    <privacy-policy-banner />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount, nextTick } from 'vue';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import AppBarDark from '../components/general/AppBarDark.vue';
import AppBarLight from '../components/general/AppBarLight.vue';
import HeroVue from '../components/home/Hero.vue';
import WelcomeMessage from '../components/home/WelcomeMessage.vue';
import BuilderMessage from '../components/home/BuilderMessage.vue';
import BigSmallMessage from '../components/home/BigSmallMessage.vue';
import BigSmallMessageNoAnimation from '../components/home/BigSmallMessageNoAnimation.vue';
import ProjectShowcase from '../components/project/ProjectShowcase.vue';
import ClientIconsDark from '../components/home/ClientIconsDark.vue';
import ContactUsFooter from '../components/general/ContactUsFooter.vue';
import ContactUsHolder from '../components/contact_us_form/ContactUsHolder.vue';
import Footer from '../components/general/Footer.vue';

import { uuid } from 'vue3-uuid';
import axios from 'axios';
import { cdn } from '../support/cdn';


const darkMode = ref(true);
const showForm = ref(false);
const endBigSmallAnimation = ref(false);

const endPaperPlaneAnimation = ref(false);
const paperplaneKey = ref(uuid.v4());

const workKey = ref(uuid.v4());

const paperplane = ref();
const paperplaneMobile = ref();

const props = withDefaults(defineProps<{
  wordpressData?: any | null,
}>(), {
  wordpressData: null,
});

const works_items = ref([]);

const works = computed(() => {
  return works_items.value;
});

const isMobile = ref(window.innerWidth <= 820);

function onResize() {
  isMobile.value = window.innerWidth <= 820;
}

const showcase = computed(() => {
  return works_items.value.filter((item:any) => item.is_not_featured === false);
});

const contactInfo = {
  name:'',
  email:'',
  company:''
};

const handleFormRequest = (payload: any) => {
  contactInfo.name = payload.name;
  contactInfo.email = payload.email;
  contactInfo.company = payload.company;
  showForm.value = true;
};

const openForm = () => {
  showForm.value = true;
};

const closeForm = () => {
  showForm.value = false;
};

function resetPaperplaneKey() {
  if (!endPaperPlaneAnimation.value) {
    paperplaneKey.value = uuid.v4();
  }
}
gsap.registerPlugin(ScrollTrigger);

onMounted(() => {
  window.onload = () => {
    nextTick(() => {
      const topRef = document.getElementById('topEle');
      if (topRef && !darkMode.value) {
        gsap.to(window, {
          scrollTo: {
            y: topRef.offsetTop,
            autoKill: false
          },
          duration: 1 ,
          ease: 'none',
        });
      }
    });
  };

  axios.get(cdn('lottie/paperplane.json')).then((response) => {
    paperplane.value = response.data;
  });

  axios.get(cdn('lottie/paperplane-mobile.json')).then((response) => {
    paperplaneMobile.value = response.data;
  });

  axios.get('/wp-json/api/v1/showcase-works?mode=home').then(response => {
    works_items.value = response.data;
    workKey.value = uuid.v4();
  });

  ScrollTrigger.create({
    trigger: '#target',
    start: 'top 16%',
    onEnter: () => {
      darkMode.value = false;
    },
    onLeaveBack: () => {
      darkMode.value = true;
    },
    markers: false,
  });
  if (props.wordpressData) {
    switch (props.wordpressData.query) {
      case 'contact-us':
        showForm.value = true;
        const urlWithoutParams = window.location.origin + window.location.pathname;
        window.history.pushState({}, document.title, urlWithoutParams);
        break;
      case 'job':
        const baseUrl = process.env.APP_URL;
        window.location.href = `${baseUrl}/careers/?goto=job-application`;
        break;
    }
  }
  window.addEventListener('resize', onResize);
  fetchBlogs();
  fetchWorks();
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
});

interface BlogMeta {
  show_see_more: boolean,
  items: Blog[]
}

interface Blog {
  title: string,
  permalink: string
}

interface Work {
  title: string,
  permalink: string
}

const worksFooter = ref<Work[]>([]);
const blogsFooter = ref<BlogMeta>({show_see_more: false, items: []});

function fetchWorks() {
  axios.get('/wp-json/api/v1/footer-works')
    .then(response => {
      worksFooter.value = response.data;
    })
    .catch(error => {
      // Handle error
      console.error('There was an error fetching the data:', error);
    });
}

function fetchBlogs() {
  axios.get('/wp-json/api/v1/footer-blogs')
    .then(response => {
      blogsFooter.value = response.data;
    })
    .catch(error => {
      // Handle error
      console.error('There was an error fetching the data:', error);
    });
}

</script>

<style scoped>

.hidden {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
