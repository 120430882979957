<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
-->
<template>
  <div class="blank-container" />
</template>

<style scoped>
.blank-container {
  position: fixed;
  background-color: #fff;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}
</style>
