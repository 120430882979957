<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: Our Story

Description:
This component displays the "Our Story" section, which includes a background image and a Swiper carousel
showcasing key moments in the company's history. Each slide includes a title, descriptions, and additional
features. Users can navigate through the history cards using Swiper, with navigation also available via keyboard keys.
The component supports both desktop and mobile views, dynamically adjusting the background image and content layout based on the current slide.

How to use it:
<OurStory
  :histories="histories"
  @close-form="closeForm"
/>
-->

<template>
  <div class="our-story-container">

    <img
      :key="bgImg"
      :src="$cdn(bgImg)"
      alt="background image"
      class="bg-image active"
    >
    <img
      :key="bgImg_old"
      :src="$cdn(bgImg_old)"
      alt="background image"
      class="bg-image"
    >'
    <div class="content">
      <div class="close-button-container">
        <img
          :src="$cdn('icons/icon_close_transparent.svg')"
          alt="Close button"
          class="close-button"
          @click="closeForm"
        >
      </div>
      <div class="title-container">
        <h1 class="title">
          Our brief history
        </h1>
      </div>

      <div class="history-container">
        <swiper
          :grab-cursor="true"
          :centered-slides="true"
          :observer="true"
          :observe-parents="true"
          :slides-per-view="`auto`"
          :pagination="false"
          :initial-slide="0"
          :space-between="`${isMobile ? 16 : 32}`"
          class="swiper-container"
          @swiper="initSwiper"
          @slide-change="adjustActiveSlideScale"
        >
          <swiper-slide
            v-for="(history, index) in histories"
            :key="`${history.title}-${index}`"
            class="history-card"
          >
            <div
              :class="`history-text-section ${index === activeSlide ? 'active' : ''}`"
              @click="centerSlide(index)"
            >
              <h2>
                {{ history.title }}
              </h2>
              <div
                v-for="description in history.descriptions"
                :key="description"
                class="history-text"
              >
                <div
                  v-if="index === activeSlide"
                  v-html="description"
                />
                <p v-else>
                  {{ textLimiter(index == activeSlide, description) }}
                </p>
              </div>
              <ul v-if="index === activeSlide">
                <li
                  v-for="(feature, i) in history.features"
                  :key="`feature-${i}`"
                ><p><b>{{ feature.title }}</b> - {{ feature.detail }}</p></li>
              </ul>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import { computed, onMounted, ref, watch } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { onBeforeUnmount } from 'vue';
import { cdn } from '../../support/cdn';
// import histories from '../assets/our-story-histories.json';

const emit = defineEmits(['closeForm']);
const swiperInstance = ref(null);

const bgImg = ref('story/img_our_story-1.jpg');
const bgImg_old = ref('story/img_our_story-1.jpg');
const initSwiper = (value: any) => {
  swiperInstance.value = value;
};

const props = defineProps({
  histories: {
    type: Array,
    required: true
  }
});

const isMobile = computed(() => {
  return window.innerWidth <= 820;
});

function  handleKeyEvent(event: KeyboardEvent) {
  switch (event.key) {
    case 'ArrowLeft':
      if (swiperInstance.value) {
        (swiperInstance.value as any).slidePrev();
      }
      break;
    case 'ArrowRight':
      if (swiperInstance.value) {
        (swiperInstance.value as any).slideNext();
      }
      break;
    case 'Escape':
      closeForm();
      break;
  }
};

onMounted(() => {
  document.addEventListener('keydown', handleKeyEvent);
  openModal();

  // initial background
  const ourStoryContainer = document.querySelector('.our-story-container');
  if (ourStoryContainer) {
    ourStoryContainer.style.backgroundImage = `url(${cdn(bgImg.value)})`;
  }

  watch(() => bgImg.value, (_, oldValue) => {
    bgImg_old.value = oldValue;
    // Preload the new image
    const newImage = new Image();
    newImage.src = cdn(bgImg.value);

    // Once the image is fully loaded, update the background
    newImage.onload = () => {
      if (ourStoryContainer) {
        ourStoryContainer.style.backgroundImage = `url(${cdn(bgImg.value)})`;
      }
    };

    // Keep the old image visible until the new one loads
    if (ourStoryContainer) {
      ourStoryContainer.style.backgroundImage = `url(${cdn(bgImg_old.value)})`;
    }
  });

});

onBeforeUnmount(() => {
  document.removeEventListener('keydown', handleKeyEvent);
});

const closeForm = () => {
  emit('closeForm');
  closeModal();
};

if (isMobile.value) {
  bgImg.value = 'story/img_our_story_mobile-1.jpg';
  bgImg_old.value = 'story/img_our_story-1.jpg';
}

let scrollPosition = 0;
function openModal() {
  scrollPosition = window.pageYOffset;  // Save the scroll position
  document.body.style.overflow = 'hidden';
  // document.body.style.position = 'fixed';  // Optional: for better handling on iOS devices
  document.body.style.top = `-${scrollPosition}px`;
}

function closeModal() {
  document.body.style.overflow = 'auto';
  // document.body.style.position = '';
  window.scrollTo(0, scrollPosition);  // Restore the scroll position
}

const activeSlide = ref(0);

const textLimiter = (active: boolean, text: string) => {
  const sanitizedText = text.replace(/<\/?p>|<\/?b>|<br>/gi, '');

  if (sanitizedText.length > 150 && !active) {
    return sanitizedText.slice(0, 150) + '...';
  }
  return sanitizedText;
};


const adjustActiveSlideScale = (swiper: any) => {
  activeSlide.value = swiper.activeIndex;
  if (isMobile.value) {
    bgImg.value = props.histories[swiper.activeIndex].imgMobile;
  }else{
    bgImg.value = props.histories[swiper.activeIndex].img;
  }
};

function centerSlide(index: number): void {
  if (swiperInstance.value) {
    (swiperInstance.value as any).slideTo(index, 500);
  }
}

window.onpopstate = (event) => {
  closeForm();
  // Prevent the default action
  event.preventDefault();
};

</script>

<style scoped>

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounceUp {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.fade-leave,
.fade-enter-to {
    position: relative;
    opacity: 1;
}

.fade-enter,
.fade-leave-to {
    position: absolute;
    opacity: 0;
}

.our-story-container{
  display: flex;
  position: fixed;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  background-size: cover; /* Ensures the image covers the container */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents repeating the image */
}

.bg-image{
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bg-image.active{
  animation: fadeIn 0.5s ease-in-out;
  z-index: 1;
}

#bg-image{
  animation: fadeIn 0.5s ease-in-out;
}

.content{
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100vh;
}

.close-button-container{
  display: flex;
  justify-content: flex-end;
  width: 100%;
  cursor: pointer;
  padding-top: 64px;
  padding-right: 64px;
}

.close-button {
  width: 40px;
  height: 40px;
}

.history-container{
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-grow: 1;
  gap: 32px;
  margin-bottom: 64px;
  align-items: flex-end;
}

.swiper-slide {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 576px !important;
  height: auto !important;
}

ul {
  list-style-type: disc;
  padding-left: 14px;
}

.history-card{
  display: flex;
  width: 100%;
  height: fit-content;
  background-color: transparent;
  overflow-y: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.history-card.active{
  animation: bounceUp 0.3s ease-in-out forwards;
}

.history-card::-webkit-scrollbar {
  display: none;
}

.history-text-section{
  display: flex;
  flex-direction: column;
  padding: 32px;
  height: 316px;
  background-color: #FFFFFFD9;
}

.history-text-section.active{
  background-color: #FFF;
  color: #242424;
  height: fit-content;
  max-height: 60vh;
  min-height: 400px;
  overflow-y: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.history-text-section.active p{
  color: #242424;
}

.history-card img{
  width: auto;
  height: auto;
  object-fit: cover;
}

.history-card h2{
  align-self: stretch;
  color: #000;
  font-family: 'Inter', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.25px;
  margin-bottom: 24px;
}

.history-card p{
  align-self: stretch;
  color: #969696;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.history-text{
  margin-bottom: 16px;
}

.title-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 64px;
}

.title{
  color: #fff;
  text-align: start;
  font-family: 'Inter', sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -1px;
  max-width: 1024px;
  width: 100%;
  margin-bottom: 24px;
  margin-left: 320px;
}

.gradient-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 30%, transparent 70%);
}

@media screen and (max-width: 820px) {
  .close-button-container{
    padding-top: 24px;
    padding-right: 24px;
  }

  .title-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    margin-top: 64px;
    padding: 0 32px;
  }

  .title{
    font-family: 'Inter' sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: -0.75px;
    text-align: left;
    margin-left: 0;
  }

  .swiper-slide {
    width: 80vw !important;
    height: auto !important;
  }

  .history-text-section{
    height: 224px;
    margin: 32px 0;
    overflow-y: hidden;
  }

  .history-text-section.active{
    padding: 40px 24px;
    margin: 32px 0;
    max-height: 60vh;
  }

  .history-card h2{
    font-family: 'Inter' sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 24px;
  }

  .history-card p{
    font-family: 'Inter' sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: left;
  }

  .close-button {
    width: 32px;
    height: 32px;
  }

}

</style>
