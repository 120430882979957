<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: Services Page

Description:
The "CredoList" component is designed to display a list of services with detailed descriptions.
It features a main title, a section for each service with subtitles, animated visuals, and detailed
descriptions for each service. Each section utilizes Lottie animations to provide visual engagement,
and the details fade in on scroll for an enhanced user experience.

How to use it:
<CredoList
  :main-title="mainTitle"
  :services="services"
/>
-->
<template>
  <div class="layout-container">
    <div class="sub-container">
      <header class="main-title">
        <h1>{{ mainTitle }}</h1>
      </header>
      <section
        v-for="(service, index) in services"
        :key="index"
        class="service-section"
      >
        <div class="left-section">
          <h4>{{ service.subtitle }}</h4>
          <h2 class="service-title">
            {{ service.title }}
          </h2>
          <LottieObj
            :url="$cdn(service.animationType)"
            :placeholder-url="$cdn('credos/code_far.svg')"
            class="lottie-obj"
          />
        </div>
        <div class="right-section">
          <div
            v-for="(detail, detailIndex) in service.details"
            :id="`${detail.reference}`"
            :key="`detail-${detailIndex}`"
            :class="{'service-detail': true, 'first-detail': detailIndex === 0}"
          >
            <h3>
              {{ detail.title }}
            </h3>
            <p
              v-for="(description, descriptionIndex) in detail.description"
              :key="`description-${descriptionIndex}`"
              class="service-description"
            >
              {{ description }}
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">

import { onMounted, ref } from 'vue';
import LottieObj from '../general/LottieObj.vue';
import { fadeInEffect } from '../../support/helper';

const mainTitle = ref('Our credos');

const props = defineProps({
  services: {
    type: Array,
    required: true
  }
});

onMounted(() => {
  props.services.forEach((service) => {
    service.details.forEach((detail) => {
      fadeInEffect(`#${detail.reference}`);
    });
  });
});

</script>

<style scoped>

.layout-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FAFAFA;
  height: 100%;
  width: 100%;
}

.sub-container{
  max-width: 1280px;
  margin: 160px 48px;

}

.main-title {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: -3px;
  margin-bottom: 16px;
}

.service-section {
  display: flex;
  border-top: 1px solid #ccc;
  padding-top: 32px;
  margin-bottom: 160px;
}

.left-section {
  position: -webkit-sticky;
  position: sticky;
  top: 160px;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
}

.left-section h4 {
  color: #969696;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.left-section h2 {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.25px;
}

.left-section .lottie-obj {
  width: 240px;
  height: auto;
}

.right-section {
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.service-detail:not(.first-detail) {
  border-top: 1px solid #ccc;
  padding-top: 32px;
}

.right-section h3{
  align-self: stretch;
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 16px;
}

.right-section p{
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 16px;
}

@media screen and (max-width: 1024px) {

  .sub-container{
    padding-top: 64px;
    margin: 0 80px;
  }

  .service-section {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #ccc;
    padding: 32px 0;
    gap: 24px;
    margin-bottom: 32px;
  }

  .left-section {
    position: relative;
    top: 0;
    min-width: 0px;
    width: 100%;
    align-items: center;
  }

  .main-title {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: -0.75px;
    margin-bottom: 32px;
  }

  .left-section h4 {
    align-self: stretch;
    color: var(--Neutrals-Nobel, #969696);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  .left-section h2 {
    align-self: stretch;
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  .left-section img {
    max-width: 193px;
    margin-top: 55px;
  }

  .right-section {
    gap: 32px;
  }

  .right-section h3{
    align-self: stretch;
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .right-section p{
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.25px;
  }

  .service-detail:not(.first-detail) {
    border-top: none;
    padding-top: 0;
  }

}

@media screen and (max-width: 480px) {
  .sub-container{
    margin: 0 24px;
  }
}
</style>
