<template>
  <div>
    <blank-screen v-show="showForm" />
    <AppBarLight
      v-show="!showGalleryModal"
      current-section="work"
      :class="`${showForm ? 'hidden' : ''}`"
      @get-in-touch="showForm = true"
    />
    <div class="spacing" />
    <WorkHeader />
    <transition name="fade">
      <ProjectShowcase
        v-if="showcase.length > 0"
        :key="workKey"
        :grid-items="showcase"
      />
      <div
        v-else-if="showLoadingWorks && showcase.length === 0"
        class="tw-flex tw-justify-center tw-items-end tw-h-[100px] tw-pb-2 tw-text-[#969696]"
      >
        Loading more content... please wait
      </div>
    </transition>
    <Testimonial
      v-if="showTestimony"
      :key="workKey"
      :data="testimonies"
    />
    <ClientIconsLight
      v-if="works.length > 0"
      :key="workKey"
      :data="works"
      :query="queryParams"
      @modal-toggle="galleryModalToggle"
    />
    <ContactUsFooter
      @open-form="handleFormRequest"
    />
    <Footer
      @get-in-touch="openForm"
    />
    <transition name="fade">
      <ContactUsHolder
        v-if="showForm"
        :name="contactInfo.name"
        :email="contactInfo.email"
        :company="contactInfo.company"
        @close-form="closeForm"
      />
    </transition>
    <privacy-policy-banner />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import AppBarLight from '../components/general/AppBarLight.vue';
import ContactUsFooter from '../components/general/ContactUsFooter.vue';
import ContactUsHolder from '../components/contact_us_form/ContactUsHolder.vue';
import WorkHeader from '../components/works/WorkHeader.vue';
import ProjectShowcase from '../components/project/ProjectShowcase.vue';
import Testimonial from '../components/general/Testimonial.vue';
import ClientIconsLight from '../components/works/ClientIconsLight.vue';
import Footer from '../components/general/Footer.vue';

import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

import ScrollToPlugin from 'gsap/ScrollToPlugin';
import axios from 'axios';
import { uuid } from 'vue3-uuid';
gsap.registerPlugin(ScrollToPlugin);

const showForm = ref(false);
const showGalleryModal = ref(false);

const workKey = ref(uuid.v4());

const showLoadingWorks = ref(true);

const contactInfo = {
  name:'',
  email:'',
  company:''
};

const handleFormRequest = (payload: any) => {
  contactInfo.name = payload.name;
  contactInfo.email = payload.email;
  contactInfo.company = payload.company;
  showForm.value = true;
};

function galleryModalToggle(value: any){
  showGalleryModal.value = value;
}
const openForm = () => {
  showForm.value = true;
};

const closeForm = () => {
  showForm.value = false;
};

const props = withDefaults(defineProps<{
  wordpressData?: any | null,
}>(), {
  wordpressData: null,
});

const works_items = ref([]);

const works = computed(() => {
  return works_items.value;
});

const showcase = computed(() => {
  return works_items.value.filter((item:any) => item.is_not_featured === false);
});

const testimonies = ref({
  testimonies:[]
});

const showTestimony = computed(() => {
  return testimonies.value.testimonies.length > 0;
});

const queryParams = ref('');

onMounted(() => {
  const params = new URLSearchParams(window.location.search);
  const goto = props.wordpressData.query;

  queryParams.value = params.get('filter') || goto;
  works_items.value = props.wordpressData.works;
  works_items.value.forEach((element: any) => {
    element.testimonies.forEach((value: any) => {
      testimonies.value.testimonies.push({testimony:value.testimony});
    });
  });
});

</script>

<style scoped>
.spacing {
  height: 320px;
  width: 100%;
}
.hidden {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media screen and (max-width: 820px) {
  .spacing {
    height: 164px;
  }
}

</style>
