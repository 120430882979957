<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: About Page
Description:
This component features a "Big Brain" Lottie animation that is displayed as part of the About page.
The animation is loaded dynamically and controlled through GSAP's ScrollTrigger, allowing it to respond
to the user's scroll actions. The animation stops and plays at specific points, creating an interactive experience.

How to use it:
<BigBrain />
-->
<template>
  <div class="big-brain-main-container">
    <div class="top-placeholder">
      <LottieAnimation
        v-if="BigBrainJSON"
        ref="anim"
        :animation-data="BigBrainJSON"
        :auto-play="false"
        :loop="false"
        class="lottie-obj"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { LottieAnimation } from 'lottie-web-vue';
import axios from 'axios';
import { cdn } from '../../support/cdn';
// import BigBrainJSON from '../lottie/bigbrain.json';

const BigBrainJSON = ref();

gsap.registerPlugin(ScrollTrigger);

const anim = ref<any>(null);

onMounted(() => {

  axios.get(cdn('lottie/bigbrain2.json')).then(response => {
    BigBrainJSON.value = response.data;
  }).finally(() => {

    if (!BigBrainJSON.value) {
      return;
    }

    let startPoint = 'top 70%';
    let endPoint = 'bottom 85%';

    gsap.to('.lottie-obj', {
      scrollTrigger: {
        trigger: '.lottie-obj',
        endTrigger: '.big-brain-main-container',
        start: startPoint,
        end: endPoint,
        scrub: true,
        // pin: true,
        onUpdate: (self) => {
          const tick = self.progress;
          if (anim.value) {
            anim.value.goToAndStop(tick * 100, true);
          }
        }
      }
    });
  });
});
</script>


<style scoped>
.big-brain-main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.top-placeholder {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
}

.lottie-obj {
  width: 50%;
}

@media screen and (max-width: 820px) {
  .lottie-obj {
    width: 100%;
  }

  .big-brain-main-container {
    margin-bottom: 0;
  }
}

</style>

