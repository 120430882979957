<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: Cookie Policy
Description:
This component represents the Cookie Policy page, providing detailed information on how Alphapod uses cookies and similar tracking technologies.
It explains the types of cookies, why they are used, how users can control them, and other relevant technologies like web beacons.
This page also includes contact information for users to reach out with further questions about cookie usage.

How to use it:
<CookiePolicy />
Include this component to display the cookie policy information on your website, ensuring transparency and compliance with privacy
regulations regarding the use of cookies and tracking technologies.
-->
<template>
  <div class="main-container">
    <div class="sub-container">
      <h1>Cookie Policy</h1>
      <div class="grey-text">
        This Cookie Policy explains how Alphapod Sdn. Bhd. uses cookies and similar technologies to recognise you when you visit our to Alphapod.com, and other websites under the Alphapod.com domain (“Websites”). It explains what these technologies are and why we use them, as well as your rights to control our use of them.
      </div>
      <br>
      <div class="grey-text">
        We may update this Cookie Policy periodically and will post any changes on this page.
      </div>
      <div class="medium-gap" />
      <hr>
      <div class="medium-gap" />
      <h2>What are Cookies?</h2>
      <br>
      <p>A cookie is a small file containing a string of characters that is sent to your computer when you visit a website. When you visit the site again, the cookie allows that site to recognise your browser. Cookies may store user preferences and other information.</p>
      <br>
      <p>Cookies provide a convenience feature to save you time, or tell the Web server that you have returned to a specific page.</p>
      <br>
      <p>Cookies set by the website owner (in this case, Alphapod) are called "first party cookies". Cookies set by parties other than the website owner are called "third party cookies". Third party cookies enable third party features or functionality to be provided on or through the website (e.g. like advertising, interactive content and analytics). The parties that set these third party cookies can recognise your computer both when it visits the website in question and also when it visits certain other websites.</p>
      <div class="medium-gap" />
      <h2>Why Do We Use Cookies?</h2>
      <br>
      <p>We use first party and third party cookies for several reasons. Some cookies are required for technical reasons in order for our Websites to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies also enable us to track and target the interests of our users to enhance the experience on our Websites and Subscription Service. Third parties serve cookies through our Websites for advertising, analytics and other purposes. This is described in more detail below.</p>
      <div class="medium-gap" />
      <h2>What Types of Cookies Do We Use and How Do We Use Them?</h2>
      <br>
      <p>The specific types of first and third party cookies served through our Websites and the purposes they perform include:</p>
      <div class="medium-gap" />
      <li>Essential website cookies <p>These cookies are strictly necessary to provide you with services available through our Websites.</p></li>
      <div class="medium-gap" />
      <li>Performance and functionality cookies <p>These cookies are used to enhance the performance and functionality of our Websites but are non-essential to their use. However, without these cookies, certain functionality may become unavailable.</p></li>
      <div class="medium-gap" />
      <li>Analytics and customisation cookies <p>These cookies collect information that is used either in aggregate form to help us understand how our Websites are being used or how effective our marketing campaigns are, or to help us customise our Websites for you.</p></li>
      <div class="medium-gap" />
      <li>Social networking cookies <p>These cookies are used to enable you to share pages and content that you find interesting on our Websites through third party social networking and other websites. These cookies may also be used for advertising purposes too.</p></li>
      <div class="medium-gap" />
      <h2>How Can I Control Cookies?</h2>
      <br>
      <p>You have the right to decide whether to accept or reject cookies. You can set or amend your web browser controls to accept or refuse cookies. If you choose to reject cookies, you may still use our website though your access to some functionality and areas of our website may be restricted. As the means by which you can refuse cookies through your web browser controls vary from browser-to-browser, you should visit your browser's help menu for more information.</p>
      <br>
      <p>In addition, most advertising networks offer you a way to opt out of targeted advertising. If you would like to find out more information, please visit <a href="https://www.aboutads.info/choices/">https://www.aboutads.info/choices/</a> or <a href="https://www.youronlinechoices.eu/">https://www.youronlinechoices.eu/</a>. You may opt out by clicking here: <a href="https://www.aboutads.info/choices/">https://www.aboutads.info/choices/</a> (or if located in the European Union, by clicking here: <a href="https://www.youronlinechoices.eu/">https://www.youronlinechoices.eu/</a>). Please note this does not opt you out of being served advertising. You will continue to receive generic advertisements.</p>
      <br>
      <p>Essential website cookies: Because these cookies are strictly necessary to deliver the Websites to you, you cannot refuse them. You can block or delete them by changing your browser settings however, as described above.</p>
      <div class="medium-gap" />
      <h2>What About Other Tracking Technologies, Like Web Beacons?</h2>
      <br>
      <p>Cookies are not the only way to recognise or track visitors to a website. We employ a software technology called clear gifs (a.k.a. Web Beacons/Web Bugs), that help us better manage the Website and Subscription Service by informing us what content is effective. Clear gifs are tiny graphics with a unique identifier, similar in function to cookies, and are used to track the online movements of Web users. In contrast to cookies, which are stored on a user's computer hard drive, clear gifs are embedded invisibly on Web pages or in emails and are about the size of the period at the end of this sentence. We use clear gifs or pixels in our HTML-based emails to let us know which emails have been opened by recipients. This allows us to gauge the effectiveness of certain communications and the effectiveness of our marketing campaigns. We tie the information gathered by clear gifs in emails to our customers' Personal Information.</p>
      <div class="medium-gap" />
      <h2>Where Can I Get Further Information?</h2>
      <br>
      <p>If you have any questions about our use of cookies or other technologies, please email us at <a href="mailto:hello+privacy@alphapod.com">hello+privacy@alphapod.com</a>.</p>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped>


.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.medium-gap{
  height: 64px;
}

.small-gap{
  height: 32px;
}

.sub-container {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: 0 24px;
  padding-bottom: 160px;
}

.sub-container h1{
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: -3px;
  margin-bottom: 32px;
}

.sub-container h2{
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.25px;
}

.sub-container h3{
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 16px;
}

.sub-container p, li{
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

ul {
  margin-left: 20px;
  list-style-type: disc;
}

li {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

li p {
  margin-top: 4px;
  padding-left: 28px;
}

.sub-container a{
  color: #007AFF;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-decoration-line: underline;
}

.grey-text{
  color: #969696;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
  .sub-container {
    margin: 64px 80px;
  }

  .medium-gap{
    height: 48px;
  }

  .small-gap{
    height: 16px;
  }

  .sub-container h1{
    font-family: 'Inter' sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: -0.75px;
    text-align: left;
    margin-bottom: 24px;
  }

  .sub-container h2{
    font-family: 'Inter' sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }

  .sub-container h3{
    font-family: 'Inter' sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: left;
  }

  .sub-container p, li{
    font-family: 'Inter' sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: left;
  }

  ul {
    margin-left: 20px;
    list-style-type: disc;
  }

  .sub-container a{
    font-family: 'Inter' sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: left;
  }

  .grey-text{
    font-family: 'Inter' sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: left;
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 480px) {
  .sub-container {
    margin: 64px 24px;
  }
}
</style>
