<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: About Page
Description:
This component provides a description section for the About page, displaying a main title,
a list of descriptive paragraphs, and an optional tagline. It utilizes animations for the title
and descriptions to create a visually appealing introduction to the company. Optional props for `title`,
`descriptions`, and `tagline` allow customization, with sensible default values for easy reuse.

How to use it:
<AboutDescription />
it has two optional params, but if not provided, it will use default values
params: title, descriptions
-->
<template>
  <div class="main-container">
    <div

      class="sub-container"
    >
      <header
        ref="target"
        class="main-title"
      >
        {{ title }}
      </header>
      <div
        ref="target2"
        class="sub-title"
      >
        <p
          v-for="(subtitle, index) in descriptions"
          :key="`subtitle-${index}`"
        >
          {{ subtitle }}
        </p>
      </div>
      <p
        v-if="tagline.length > 0"
        class="tagline"
      >
        {{ tagline }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">

import { ref } from 'vue';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const target = ref<any>(null);
const target2 = ref<any>(null);


defineProps({
  title: {
    type: String,
    default: 'Untypical.'
  },
  descriptions: {
    type: Array,
    default: () => [
      'We’re not a “digital agency”. In fact, we’re anything but. While the typical agency is driven to maximise its billable hours (read: getting you to spend as much money as possible) Alphapod is focused on delivering tangible results—from user acquisition to conversion, we’re constantly looking at ways to optimise the metrics that are most important for your business.',
      'As entrepreneurs ourselves, we understand that the business landscape is constantly evolving. Technology evolves at breakneck speed, so delivering long-lasting value requires not just cutting-edge technical expertise but also a deep understanding of business and users. From startup entrepreneurs to enterprise-level clients, all of our best work is the result of long-term partnership engagements that have seen us build digital products that have evolved over multiple years. Our builder culture has also led us to create our own digital products, some of which have been featured numerous times on the world stage.',
    ]
  },
  tagline: {
    type: String,
    default: ''
  }
});

</script>

<style scoped>
.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FAFAFA;
}

.sub-container{
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: 160px 24px;
}

.main-title {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: -3px;
  margin-bottom: 32px;
}

.sub-title p{
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 16px;
}

.tagline {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 120px;
}

@media screen and (max-width: 820px) {

  .sub-container{
    margin: 80px;
  }
  .main-title {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: -0.75px;
  }

  .sub-title p{
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .tagline {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
  }
}

@media screen and (max-width: 480px) {
  .sub-container{
    margin: 80px 24px;
  }
}
</style>

