<template>
  <div class="layout-container">
    <div class="inner-container">
      <!-- title -->
      <h1 class="title animate-highlight-section1">
        Our project highlights
      </h1>
      <!-- subtitle -->
      <p class="subtitle animate-highlight-section2">
        Over the years, we’ve had the privilege of partnering with some of the world’s leading brands and innovative companies across a wide range of industries.
        <a
          href="https://dstnct.co/work"
          target="_blank"
        >
          View more projects
        </a>
      </p>
      <!-- <div v-if="isMobile">
        <Swiper
          slides-per-view="auto"
          :free-mode="true"
          space-between="8"
          :slides-offset-before="24"
          :slides-offset-after="24"
        >
          <SwiperSlide
            v-for="(category, index) in visibleCategories"
            :key="`category-${index}`"
            class="category-button"
            :class="{ 'is-selected': selectedCategory === category }"
            @click="handleSelectCategory(category)"
          >
            {{ category }}
          </SwiperSlide>
          <SwiperSlide
            v-if="showMoreButton"
            class="category-button"
            @click="toggleVisibility"
          >
            {{ exceedTag }}
          </SwiperSlide>
        </Swiper>
      </div>

      <div
        v-else
        class="categories"
      >
        <div
          v-for="(category, index) in visibleCategories"
          :key="`category-${index}`"
          class="category-button"
          :class="{ 'is-selected': selectedCategory === category }"
          @click="handleSelectCategory(category)"
        >
          {{ category }}
        </div>
        <div
          v-if="showMoreButton"
          class="category-button"
          @click="toggleVisibility"
        >
          {{ exceedTag }}
        </div>

      </div> -->
      <!-- grid display -->
      <div class="brands-grid animate-highlight-section3">
        <TransitionGroup>
          <div
            v-for="brand in filteredBrands"
            :key="`${brand.uuid}`"
            class="brand"
          >
            <img
              :src="$cdn(brand.icon.sizes.large)"
              :alt="brand.icon.alt"
            >
          </div>
        </TransitionGroup>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, type Ref, onMounted } from 'vue';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import { uuid } from 'vue3-uuid';
import { DNAProject } from '../../support/model';
// import works from '../assets/dna-projects.json';
import { fadeInEffect } from '../../support/helper';

gsap.registerPlugin(ScrollTrigger);

const emit = defineEmits(['show-paperplane', 'on-change-list']);

const isMobile = computed(() => {
  return window.innerWidth <= 480;
});

const props = defineProps({
  works: {
    type: Array,
    required: true,
  }
});

const data = ref<DNAProject[]>([]);

onMounted(() => {
  // const elements = ['.inner-container .title', '.inner-container .subtitle', '.inner-container .categories', '.inner-container .brands-grid'];
  // elements.forEach((selector) => {

  //   gsap.fromTo(selector, {
  //     opacity: 0,
  //   }, {
  //     opacity: 1,
  //     scrollTrigger: {
  //       trigger: selector,
  //       start: 'top 75%',
  //       toggleActions: 'play none none none',
  //     }
  //   });


  // });

  data.value = props.works;

  let onBottom = false;

  ScrollTrigger.create({
    trigger: '.layout-container',
    start:'bottom bottom',
    onEnter: () => {
      if (!onBottom) {
        onBottom = true;
        emit('show-paperplane');
      }
    }
  });
  fadeInEffect('.animate-highlight-section1');
  fadeInEffect('.animate-highlight-section2');
  fadeInEffect('.animate-highlight-section3');
});



const brands: Ref<DNAProject[]> = computed(() => data.value.filter((item: DNAProject) => item.icon.available));
const categories = computed(() => {
  // Assuming `brands` is reactive
  const allCategories = brands.value.flatMap(brand => brand.category);
  const uniqueCategories = new Set(allCategories);
  return ['All', ...Array.from(uniqueCategories)];
});

const exceedNum = 4;

// const isTagExceeded = ref(false);

const selectedCategory = ref('All');

// This computed property now calculates if the "more" button should be shown
const showMoreButton = computed(() => categories.value.length > exceedNum);

// New computed property to control visible categories
const visibleCategories = computed(() => {
  if (!showAll.value) {
    return categories.value.slice(0, exceedNum);
  }
  return categories.value;
});

// New reactive property to toggle showing all categories
const showAll = ref(false);

const toggleVisibility = () => {
  showAll.value = !showAll.value;
  if (!showAll.value) {
    selectedCategory.value = 'All';
  }
};

function handleSelectCategory(category: string) {
  emit('on-change-list');
  selectedCategory.value = category;
}

// Adjusted exceedTag to better handle showing remaining items count
const exceedTag = computed(() => {
  const exceed = categories.value.length - exceedNum;
  if (exceed > 0 && !showAll.value) {
    return `${exceed}+ more`;
  } else if (showAll.value) {
    return 'Show less';
  }
  return '';
});


// Filter brands based on the selected category
const filteredBrands = computed(() => {
  // First filter the brands based on the selected category
  let result = brands.value;
  if (selectedCategory.value !== 'All') {
    result = brands.value.filter(brand => brand.category.includes(selectedCategory.value));
  }
  // Then sort the resulting array alphabetically by brand name
  result.sort((a, b) => a.title.localeCompare(b.title));
  //append unique id to each of the brands in the list
  result.forEach((brand) => {
    brand.uuid = uuid.v4();
  });
  return result;
});

</script>


<style scoped>

.hidden {
  display: none;
}

.v-enter-from, .v-leave-to {
  opacity: 0;
  transform: scale(0);
}
.v-enter-to, .v-leave-from {
  opacity: 1;
  transform: scale(1);
}

.v-enter-active,
.v-leave-active,
.v-move {
  transition: all 0.7s;
}
.v-leave-active { position: absolute; }

.layout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  background-color: #000; /* Assuming a dark theme like in the screenshot */
  text-align: center;
}

.inner-container {
  width: 100%;
  max-width: 1280px;
  padding-bottom: 160px;
}

.title {
  color: #FAFAFA;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Inter', sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px; /* 125% */
  letter-spacing: -3px;
  text-align: start;
  margin-bottom: 32px;
}

.subtitle {
  color: #FFF;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 160% */
  text-align: start;
  margin-bottom: 120px;
}

.subtitle a {
  text-underline-offset: 2px;
  text-decoration: underline;
}

.categories {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  padding-bottom: 64px;
  padding-left: 89.5px;
  padding-right: 89.5px;
}

.category-button {
  display: inline-block;
  border-radius: 12px;
  background-color: #242424;
  color: white; /* White text color */
  border: none;
  cursor: pointer;
  outline: none; /* Removes the outline to match the design */
  transition: background-color 0.3s; /* Smooth transition for background color */
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0px;
  border-radius: 12px;
  padding: 16px 32px;
  text-align: left;
  width: fit-content;
}

.category-button.is-selected {
  background: rgba(255, 255, 255, 0.75);
  color: #242424;
}

.category-button.is-selected:hover {
  background: rgba(255, 255, 255, 0.75);
}

/* Optionally, change the hover state for additional user feedback */
.category-button:hover {
  background-color: #555; /* Lighter background on hover */
}


.brands-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.brand {
  display: flex;
  max-width: 256px;
  max-height: 120px;
  width: 100%;
  height: 120px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.brand img{
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
}


@media screen and (max-width: 1024px) {
  .inner-container {
    padding: 80px;
  }
}

@media screen and (max-width: 820px) {

  .inner-container {
    padding: 0 24px;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .title {
    color: var(--Neutrals-White, #FFF);
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -2px;
    padding: 0;
  }

  .subtitle {
    color: var(--Neutrals-White, #FFF);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    padding: 0;
  }

}

@media screen and (max-width: 480px) {
  .title {
    padding: 32px 0;
  }

  .subtitle {
    padding: 0;
    padding-bottom: 32px;
    margin: 0;
  }

  .brand {
    max-width: 114px;
    max-height: 53.15px;
  }


  .brands-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }

  .swiper {
    width: 100%;
    margin-bottom: 32px;
  }

  .categories {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    width: 100%;
    height: auto;
    padding-bottom: 32px;
    margin-left: 0;
    gap: 16px;
    /* Hide scrollbar for IE, Edge, and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  /* Style to hide scrollbar on Webkit browsers like Chrome and Safari */
  .categories::-webkit-scrollbar {
    display: none;
  }

  .category-button {
    display: inline-flex; /* Use inline-flex to allow content-based width */
    align-items: center; /* Center content vertically */
    font-family: 'Inter' sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: center; /* Center text if you prefer, but it's optional */
    padding: 12px 24px;
    white-space: nowrap; /* Prevent text wrapping */
  }
}
</style>
