<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: Blog Page
Description:
This component displays a list of blog posts, allowing users to filter posts by category and load more posts as needed.
It provides a responsive design that adjusts the category selection view based on the device type (using a Swiper slider
on tablets and buttons otherwise). The component features a "Load more" button for paginated loading, ensuring users can easily explore additional content.

How to use it:
<BlogLandingContent :tags="tagsArray" :tag="selectedTag" />
Pass `tags` as an array of `BlogTag` objects to define available categories, and optionally pass `tag` to set the initially selected category.
The component will display blog cards for the selected category and allow users to load more posts.
-->

<template>
  <div class="main-container">
    <div class="sub-container">
      <div
        v-if="isTablet"
        :class="categories.length <= 1 ? 'hidden':''"
      >
        <Swiper
          slides-per-view="auto"
          :free-mode="true"
          space-between="8"
          :slides-offset-after="24"
          :slides-offset-before="24"
        >
          <SwiperSlide
            v-for="(category, index) in categories"
            :key="`category-${index}`"
            class="category-button"
            :class="{ 'is-selected': selectedCategory === category }"
            @click="onSelectCategory(category)"
          >
            {{ category }}
          </SwiperSlide>
        </Swiper>
      </div>
      <div
        v-else
        class="categories"
        :class="categories.length <= 1 ? 'hidden':''"
      >
        <button
          v-for="(category, index) in categories"
          :key="`category-${index}`"
          class="category-button"
          :class="{ 'is-selected': selectedCategory === category }"
          @click="onSelectCategory(category)"
        >
          {{ category }}
        </button>
      </div>
      <div
        class="selected-category-label"
        :class="categories.length <= 1 ? 'shrink':''"
      >
        {{ selectedCategory }}
      </div>
      <div class="selected-category-total">
        {{ totalCategory }} Stories
      </div>
      <hr class="blog-top-divider">
      <div class="blog-card-container">
        <BlogCard
          v-for="(blog, index) in blogs"
          :key="`blog-${index}`"
          :blog="blog"
        />
      </div>
      <div class="load-more-container">
        <button
          v-if="hasNextPage"
          class="load-more-btn"
          @click="onLoadMore"
        >
          Load more
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">


import { ref, computed, onMounted } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import BlogCard from './BlogCard.vue';
import { getBlogs } from '../../support/blogService';
import { BlogResponse, BlogItem, BlogTag } from '../../support/model';

const props = defineProps({
  tags: {
    type: Array as () => BlogTag[],
    default: () => [],
    required: true
  },
  tag:{
    type: Object as () => BlogTag,
    default: undefined,
  }
});


const selectedCategory = ref('');
const totalCategory = ref(0);
const page = ref(1);
const hasNextPage = ref(false);
const categories = computed(() => {
  return [...props.tags.map((tag) => tag.name)];
});
const blogs = ref<BlogItem[]>([]);

function onSelectCategory(category: string) {
  selectedCategory.value = category;
  page.value = 1;
  blogs.value = [];
  getBlogs(page.value,8, getSlug(category)).then((res: BlogResponse) => {
    totalCategory.value = res.total_posts;
    blogs.value = res.blogs;
    hasNextPage.value = res.has_next_page;
  });
}

function getSlug(name: string){
  try {
    const slug = props.tags.filter((tag) => tag.name === name)[0].slug;
    return slug;
  }catch (error) {
    return '';
  }
}

const isTablet = computed(() => {
  return window.innerWidth <= 820;
});

onMounted(() => {
  if (props.tag) {
    selectedCategory.value = props.tag.name;
  }else{
    selectedCategory.value = categories.value[0];
  }
  getBlogs(page.value,8, getSlug(selectedCategory.value)).then((res: BlogResponse) => {
    totalCategory.value = res.total_posts;
    blogs.value = res.blogs;
    hasNextPage.value = res.has_next_page;
  });
});

function onLoadMore() {
  getBlogs(++page.value,8,getSlug(selectedCategory.value)).then((res: BlogResponse) => {
    totalCategory.value = res.total_posts;
    blogs.value = blogs.value.concat(res.blogs);
    hasNextPage.value = res.has_next_page;
  });
}

</script>

<style scoped>

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sub-container {
  display: flex;
  flex-direction: column;
  max-width: 1120px;
  width: 100%;
  padding: 0 48px;
  margin-bottom: 160px;
}

.blog-card-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 64px;
}

.categories {
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
}

.hidden {
  display: none;
}

.category-button {
  height: 64px;
  border-radius: 12px;
  background: #F0F0F0;
  color: black;
  border: none;
  padding: 16px 24px;
  margin: 0 5px;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  cursor: pointer;
  outline: none; /* Removes the outline to match the design */
  transition: background-color 0.3s; /* Smooth transition for background color */
}

.category-button.is-selected {
  border-radius: 12px;
  background: #242424;
  color: white;
}

/* Optionally, change the hover state for additional user feedback */
.category-button:hover {
  background-color: #e0e0e0; /* Lighter background on hover */
}

.category-button.is-selected:hover {
  background: #242424;
}

.selected-category-label{
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -1px;
  text-align: center;
  padding-bottom: 16px;
}

.selected-category-total{
  color: #969696;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  padding-bottom: 80px;
}

.blog-top-divider{
  padding-bottom: 80px;
}

.load-more-btn{
  width: 150px;
  height: 64px;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #DCDCDC;
  margin-top: 32px;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.load-more-btn:hover{
  background-color: #fafafa;
}

@media screen and (max-width: 820px) {
  .sub-container {
    padding: 0;
    margin-bottom: 80px;
  }

  .selected-category-label,
  .selected-category-total,
  .blog-top-divider{
    margin-left: 24px;
    margin-right: 24px;
  }

  .blog-card-container{
    gap: 40px;
    padding: 0 24px;
  }

  .category-button {
    width: fit-content;
    height: 48px;
    display: inline-flex;
    align-items: center;
    font-family: 'Inter' sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: center;
    padding: 12px 24px;
    white-space: nowrap;
    border-radius: 10px;
  }

  .category-button.is-selected {
    border-radius: 10px;
  }

  .selected-category-label{
    font-size: 32px;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: -0.75px;
    padding-top: 64px;
    padding-bottom: 16px;
  }

  .selected-category-label.shrink{
    padding-top: 16px;
  }

  .selected-category-total{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding-bottom: 64px;
  }



  .blog-top-divider{
    padding-bottom: 64px;
  }

  .load-more-container{
    display: flex;
    justify-content: start;
    padding: 0 24px;
  }

  .load-more-btn{
    width: 100%;
    height: 48px;
    padding: 12px 0;
    border-radius: 10px;
    margin-top: 32px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
  }
}
</style>
