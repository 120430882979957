<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: Contact Us Form
Description:
This component represents the first page of the multi-step "Contact Us" form, where users provide basic
information about themselves, including their name, email, organisation, role, company type, and contact number.
The form uses custom dropdown components for selecting the company type and country code for the phone number,
providing a user-friendly experience.

How to use it:
<ContactUsPageOne
  :name="'John Doe'"
  :email="'johndoe@example.com'"
  :company="'Startup'"
  :organization="'Acme Corp.'"
  :role="'CEO'"
  :country-code="'60'"
  :phone-number="'123456789'"
  @submit="handlePageOneSubmit"
/>
Pass props such as `name`, `email`, `company`, `organization`, `role`, `countryCode`, and `phoneNumber` to
pre-fill the respective fields. The component emits a `submit` event when the user clicks "Next."
-->
<template>
  <div
    ref="formContainer"
    class="page-one-container"
  >
    <h2 class="form-title">
      Tell us about yourself
    </h2>
    <form
      class="contact-form"
      @submit.prevent="submitForm"
    >
      <div class="user-information">
        <!-- name -->
        <div class="form-group">
          <label for="name">Your name</label>
          <input
            id="name"
            v-model="form.name"
            type="text"
            placeholder="Full name"
            autocomplete="name"
          >
          <span
            v-if="formSubmitted && !form.name"
            class="form-error"
          >Name is required.</span>
        </div>
        <!-- email -->
        <div class="form-group">
          <label for="email">Email</label>
          <input
            id="email"
            v-model="form.email"
            type="email"
            placeholder="mail@example.com"
            autocomplete="email"
          >
          <span
            v-if="form.email.length > 0 && !validEmail"
            class="form-error"
          >Please enter a valid email address.</span>
        </div>
        <!-- organisation -->
        <div class="form-group">
          <label for="email">Your organisation</label>
          <input
            id="organization"
            v-model="form.organization"
            type="text"
            placeholder="Acme Corp."
          >
        </div>
        <!-- role -->
        <div class="form-group">
          <label for="email">Your role</label>
          <input
            id="role"
            v-model="form.role"
            type="text"
            placeholder="Marketing Manager"
          >
        </div>
        <!-- company -->
        <div class="form-group">
          <label for="company">How would you describe your organisation?</label>
          <CustomDropdown
            :options="companyOptions.map(option => option.value)"
            :form-container="formContainer"
            :initial-value="form.company"
            @select-dropdown-item="form.company = $event"
          />
          <span
            v-if="formSubmitted && !form.company"
            class="form-error"
          >Company type is required.</span>
        </div>
        <!-- phone number -->
        <div class="form-group">
          <label for="email">Contact number <span>(optional)</span></label>
          <PhoneNumberDropdown
            :form-container="formContainer"
            :country-code="form.countryCode"
            :phone-number="form.phoneNumber"
            @phone-number-valid="validPhoneNumber = $event"
            @update-phone-number="form.phoneNumber = $event"
            @update-country-code="form.countryCode = $event"
          />
        </div>
        <div class="button-container">
          <button
            type="submit"
            :class="`submit-button ${!isFormValid ? 'disabled' : ''}`"
            :disabled="!isFormValid"
          >
            Next
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">

import PhoneNumberDropdown from '../general/PhoneNumberDropdown.vue';
import { reactive, computed, ref } from 'vue';
import CustomDropdown from '../general/CustomDropdown.vue';

// Define component events
const emit = defineEmits(['submit']);

// Define component props
const props = defineProps({
  name: {
    type: String,
    default: '',
  },
  email: {
    type: String,
    default: '',
  },
  company: {
    type: String,
    default: 'Please select',
  },
  organization: {
    type: String,
    default: '',
  },
  role: {
    type: String,
    default: '',
  },
  phoneNumber: {
    type: String,
    default: '',
  },
  countryCode: {
    type: String,
    default: '+60',
  },
});

// Initialize form state
const form = reactive({
  name: props.name || '',
  email: props.email || '',
  company: props.company || '',
  organization: props.organization || '',
  role: props.role || '',
  phoneNumber: props.phoneNumber || '',
  countryCode: props.countryCode || '+60',
});

// Initialize other references and state variables
const formSubmitted = ref(false);
const formContainer = ref(undefined);

const validPhoneNumber = ref(true);

// Options for the company dropdown
const companyOptions = ref([
  { value: 'Startup' },
  { value: 'Small/Medium Enterprise' },
  { value: 'Large Corporation' },
  { value: 'Non-Profit' },
  { value: 'Other' },
]);

// Computed value to validate email format
const validEmail = computed(() => {
  const emailRegex = /^\S+@\S+\.\S+$/;
  return emailRegex.test(form.email);
});

const validCompany = computed(() => {
  return companyOptions.value.some(option => option.value === form.company);
});

// Computed value to check if the form is valid
const isFormValid = computed(() => {
  return form.name && validEmail.value && form.company && form.organization && form.role && validPhoneNumber.value && validCompany.value;
});

// Function to submit the form
const submitForm = () => {
  if (isFormValid.value) {
    formSubmitted.value = true;
    emit('submit', form);
  }
};
</script>


<style scoped>
.page-one-container{
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.page-one-container::-webkit-scrollbar {
  display: none;
}

.contact-form .user-information{
  display: grid;
  gap: 24px;
  column-gap: 16px;
  grid-template-columns: 1fr 1fr;
}

.form-title {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.5px;
  margin-bottom: 64px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

.form-group label {
  display: block;
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 12px;
}

.form-group input,
.form-group select {
  width: calc(100% - 6px);
  margin: 0 3px;
  height: 64px;
  border-radius: 12px;
  border: 1px solid var(--Neutrals-Gainsboro, #DCDCDC);
  outline: none;
  background-color: #FFF;
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  padding: 16px 24px;
}

.form-group input:focus, .form-group select:focus {
  border: 1px solid var(--Neutrals-Gainsboro, transparent);;
  outline: 2px solid var(--Neutrals-Gainsboro, #007aff);
}

.form-group span {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
}

.form-group .form-error {
  color: #DC4664;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.25px;
  text-align: left;
  margin-top: 12px;
}

.form-group input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right 24px center;
}

.submit-button {
  color: #FFF;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 64px 0;
  width: 93px;
  height: 64px;
  padding: 16px 24px;
  border: none;
  cursor: pointer;
  border-radius: 12px;
  background-color: #007AFF;
  transition: all 0.3s ease;
}

.submit-button:hover {
  opacity: 0.8;
}

.submit-button.disabled {
  color: #969696;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  background-color: transparent;
  border-radius: 12px;
  border: 1px solid var(--Neutrals-Gainsboro, #DCDCDC);
  cursor: not-allowed;
}

.dropdown-content{
  max-height: 200px;
  min-width: 300px;
  overflow-y: auto;
}

.dropdown-item{
  width: 100%;
  padding: 4px 8px;
}

.dropdown-item.active, .dropdown-item:hover {
  background-color: #007AFF;
  color: #FFF;
}

@media screen and (max-width: 1000px) {
  .form-title {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 40px;
  }

  .contact-form{
    width: 100%;
  }

  .contact-form .user-information{
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .phone-number-container{
    display: flex;
    gap: 12px;
  }

  .form-group {
    margin-bottom: 16px;
  }



  .form-group label {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
    margin-bottom: 12px;
  }

  .form-error {
    font-size: 14px !important;
  }

  .form-group input,
  .form-group select {
    width: calc(100% - 6px);
    margin: 0 3px;
    height: 48px;
    padding: 12px 24px;
    background-color: #fff;
    border: 1px solid var(--Neutrals-Gainsboro, #DCDCDC);
    border-radius: 10px;
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    background-size: 12px 12px;
  }

  .form-group span {
    font-size: 16px;
    line-height: 24px;
  }

  .button-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 80px;
    width: 100%;
  }
  .submit-button {
    width: 84px;
    height: 48px;
    padding: 12px 24px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    border-radius: 10px;
  }

  .submit-button.disabled {
    width: 84px;
    height: 48px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    border-radius: 10px;
  }
}

</style>
