<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: About Page
Description:
This component renders the main header for the About page, which includes a prominent title,
a subtitle paragraph, and a banner image with a motivational quote. It provides an engaging
introduction to Alphapod's story and mission, combining text with visual elements for an appealing presentation.

How to use it:
<AboutHeader />
-->
<template>
  <div class="main-container">
    <div class="sub-container">
      <header class="main-title">
        {{ title }}
      </header>
      <div class="sub-title">
        <p>
          {{ subtitle }}
        </p>
      </div>
    </div>
    <div class="header-banner">
      <img
        v-once
        :src="$cdn('about/img_hero_about.jpg')"
        alt="about alphapod"
      >
      <div
        class="quote"
      >
        Like all great things, it began with an idea.
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const title = ref('This is Alphapod.');
const subtitle = ref('Alphapod is a mobile-first digital product studio, with a mission to enable businesses and creators to change the world through technology. We’ve come a long way since our founding in 2009—from building our first mobile app for the iPhone 3GS to working with some of the world’s most recognisable brands today.');

</script>

<style scoped>
.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sub-container{
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: 0px 24px;
}

.main-title {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 120px;
  font-style: normal;
  font-weight: 700;
  line-height: 120px;
  letter-spacing: -5px;
  margin-bottom: 64px;
}

.sub-title {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 120px;
}

.header-banner {
  position: relative;
  width: 100%;
}

.header-banner img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.quote {
  max-width: 560px;
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -40%);
  color: #fff;
  font-family: 'Inter', sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -1px;
  background-color: #242424;
  padding: 32px;
}

@media screen and (max-width: 1024px) {
  .quote {
    max-width: 420px;
    font-size: 32px;
    line-height: 42px;
  }
}

@media screen and (max-width: 820px) {
  .sub-container{
    margin: 0px 80px;
  }

  .main-title {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1px;
    margin-top: 120px;
    margin-bottom: 24px;
  }

  .sub-title {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    margin-bottom: 64px;
  }

  .header-banner img {
    height: 260px;
    object-fit: cover;
  }

  .quote {
    max-width: 208px;
    color: var(--Neutrals-White, #FFF);
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 120% */
    background-color: #242424;
    padding: 16px;
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 480px) {
  .sub-container{
    margin: 0px 24px;
  }
}
</style>

