<template>
  <div>
    <blank-screen v-show="showForm || showJobForm" />
    <AppBarLight
      current-section="careers"
      :class="`${showForm || showJobForm ? 'hidden' : ''}`"
      @get-in-touch="showForm = true"
    />
    <div class="spacing" />
    <CareerHeader />
    <CareerPath
      v-if="DestinationJSON"
      title="You have arrived at your destination."
      :image="DestinationJSON"
      :description="[
        '<p>There\'s work. And then there\'s your <i>life\'s work.</i></p>',
        '<p>The kind of work that has your fingerprints all over it.</p>',
        '<p>The kind of work that you\'d never compromise on.</p>',
        '<p>The kind of work you\'d be willing to sacrifice a weekend for.</p>',
        '<br>',
        '<p>You can do that kind of work at Alphapod. Something impactful and meaningful. Something that couldn\'t happen anywhere else.</p>',
        '<br>',
        '<b>This is Alphapod.</b>',
      ]"
      link-title="<p></p>"
      link-url=""
    />

    <CareerPath
      v-if="ClappingJSON"
      title="Do great work immersed in an awesome culture."
      :description="[
        '<p>Our success is the collective result of the passion and hard work of every team member. We value gratitude, honour, and humility in our interactions with one another, recognising that each person\'s contribution is essential to our shared goals. There\'s no room here for egos or prima donna rockstars.</p>',
        '<br>',
        `<p>Read all about <a href='/about/?goto=value'>our values.</a></p>`,
      ]"
      :image="ClappingJSON"
      :image-is-left="true"
      link-title="<p></p>"
      link-url=""
      :is-gray-bg="true"
    />
    <CareerPath
      v-if="AnimationStarsJSON"
      title="Growth."
      :description="[
        '<p>At Alphapod, you\'ll create meaningful impact through your work in an environment that celebrates creativity, collaboration, and excellence.</p>',
        '',
        '<p>Whether your expertise lies in design, software engineering, or business development, you\'ll be part of a solutions-oriented team that pushes boundaries and redefines what\'s possible.</p>',
        '',
        '<p>Join us, and let\'s shape the future together. The journey will be rewarding, we promise.</p>',
        ''
      ]"
      :image="AnimationStarsJSON"
      link-title="<p></p>"
      link-url=""
      :is-autoplay="true"
    />
    <OpenPosition
      v-if="jobPostingEntries.length > 0"
      :services="jobPostingEntries"
      :query="wordpressData.query"
      @apply-job="handleJobApplication"
    />
    <ContactUsFooter
      @open-form="handleFormRequest"
    />
    <transition name="fade">
      <ContactUsHolder
        v-if="showForm"
        :name="contactInfo.name"
        :email="contactInfo.email"
        :company="contactInfo.company"
        @close-form="closeForm"
      />
    </transition>
    <transition name="fade">
      <JobApplicationHolder
        v-if="showJobForm"
        :position="applyJobDetails.position"
        :department="applyJobDetails.department"
        @close-job-form="showJobForm = false"
      />
    </transition>
    <Footer
      @get-in-touch="openForm"
    />
    <privacy-policy-banner />
  </div>
</template>

<script setup lang="ts">
import { nextTick, onMounted, ref, watch } from 'vue';
import AppBarLight from '../components/general/AppBarLight.vue';
import ContactUsFooter from '../components/general/ContactUsFooter.vue';
import ContactUsHolder from '../components/contact_us_form/ContactUsHolder.vue';
import CareerHeader from '../components/careers/CareerHeader.vue';
import CareerPath from '../components/careers/CareerPath.vue';
import OpenPosition from '../components/careers/OpenPosition.vue';
import Footer from '../components/general/Footer.vue';
import JobApplicationHolder from '../components/job_application_form/JobApplicationHolder.vue';

import axios from 'axios';
import { cdn } from '../support/cdn';

const showForm = ref(false);
const showJobForm = ref(false);

const contactInfo = {
  name: '',
  email: '',
  company: '',
};

const ClappingJSON = ref();
const DestinationJSON = ref();
const AnimationStarsJSON = ref();

const jsonPaths = [
  'lottie/destination.json',
  'lottie/animation_clapping_v2.json',
  'lottie/animation_stars.json',
];

const jobPostingEntries = ref([]);

withDefaults(defineProps<{
  wordpressData?: any | null,
}>(), {
  wordpressData: null,
});

onMounted(() => {
  watch(showJobForm, (value) => {
    if (value) {
      openModal();
    }else{
      closeModal();
    }
  });

  nextTick(() => {
    jsonPaths.forEach((path) => {
      axios.get(cdn(path)).then((response) => {
        if (path === 'lottie/animation_clapping_v2.json') {
          ClappingJSON.value = response.data;
        } else if (path === 'lottie/destination.json') {
          DestinationJSON.value = response.data;
        } else if (path === 'lottie/animation_stars.json') {
          AnimationStarsJSON.value = response.data;
        }
      });
    });
  });

  axios.get(cdn('json/career-open-position.json')).then((response) => {
    jobPostingEntries.value = response.data;
  });
});

const handleFormRequest = (payload: any) => {
  contactInfo.name = payload.name;
  contactInfo.email = payload.email;
  contactInfo.company = payload.company;
  showForm.value = true;
};

const handleJobApplication = (payload: any) => {
  applyJobDetails.position = payload.title;
  applyJobDetails.department = payload.subtitle;
  showJobForm.value = true;
};

let scrollPosition = 0;

function openModal() {
  scrollPosition = window.pageYOffset;  // Save the scroll position
  document.body.style.overflow = 'hidden';
  // document.body.style.position = 'fixed';  // Optional: for better handling on iOS devices
  document.body.style.top = `-${scrollPosition}px`;
}

function closeModal() {
  document.body.style.overflow = 'auto';
  // document.body.style.position = '';
  window.scrollTo(0, scrollPosition);  // Restore the scroll position
}


const applyJobDetails = {
  position: undefined,
  department: undefined,
};

const openForm = () => {
  showForm.value = true;
};

const closeForm = () => {
  showForm.value = false;
};
</script>

<style scoped>
.spacing {
  height: 320px;
  width: 100%;
}

.hidden {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media screen and (max-width: 820px) {
  .spacing {
    height: 0;
  }
}
</style>
