<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: General Components
Description:
This component represents a dynamic navigation header for the website, including both a desktop and a mobile version.
The header adapts to the scrolling and resizing actions of the user, shrinking or expanding based on the viewport position.
On desktop, it includes navigation links and a "Get in Touch" button, while on mobile, it displays a simplified app bar.
The header also features smooth animations for menu items during scrolling.

How to use it:
<AppBarLight :current-section="'services'" :initial-expand="true" @getInTouch="handleGetInTouch" />
Pass `currentSection` to highlight the active section of the page in the navigation menu. Use `initialExpand`
to control whether the header is initially expanded. The `getInTouch` event can be used to handle interactions when the "Get in Touch" link is clicked.
-->
<template>
  <div
    v-if="!isMobile"
    id="top-nav"
  >
    <div class="main-container">
      <!-- navbar with nav menu and logo -->
      <header
        v-show="hideNavBar"
        class="header-container shrink"
      >
        <div class="sub-container">
          <nav>
            <div
              class="blurred-background"
              :class="{ 'safari-background': isSafari }"
            >
              <img
                class="header-nav-icon"
                :src="`${isMobile? $cdn('icons/icon_hamburger_mobile_dark.svg') : $cdn('icons/icon_hamburger_dark.svg')}`"
                alt="Navigation menu"
                @click="onClickNavIcon"
              >
            </div>
          </nav>
        </div>
      </header>
      <header
        v-show="!hideNavBar"
        class="header-container"
        :class="{ 'light': !hideNavBar }"
      >
        <div
          v-show="!hideNavBar"
          class="sub-container"
        >
          <div class="logo">
            <a href="/"><img
              :src="$cdn('logos/alphapod-logo-light.svg')"
              alt="Logo"
              :class="`header-nav-logo ${(isClick || showMenu) ? 'light' : ''}`"
              style="height: 32px;"
            ></a>
          </div>
          <nav class="nav-menu">
            <ul>
              <li><a
                id="nav-menu-1"
                :class="`services ${currentSection === 'services' ? 'active' : ''}`"
                href="/services/"
              >Services</a></li>
              <li> <a
                id="nav-menu-2"
                :class="`work ${currentSection === 'work' ? 'active' : ''}`"
                href="/work/"
              >Work</a></li>
              <li> <a
                id="nav-menu-3"
                :class="`about ${currentSection === 'about' ? 'active' : ''}`"
                href="/about/"
              >About</a></li>
              <li><a
                id="nav-menu-4"
                :class="`careers ${currentSection === 'careers' ? 'active' : ''}`"
                href="/careers/"
              >Careers</a></li>
              <li> <a
                id="nav-menu-5"
                :class="`contact`"
                href="#"
                @click="getInTouch"
              >Get in Touch</a></li>
            </ul>
          </nav>
        </div>
      </header>
    </div>
  </div>
  <AppBarLightMobile
    v-else
    @get-in-touch="getInTouch"
  />
</template>

<script setup lang="ts">
import AppBarLightMobile from './AppBarLightMobile.vue';
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';
import { gsap } from 'gsap';

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const props = defineProps({
  currentSection: {
    type: String,
    default: undefined
  },
  initialExpand: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['getInTouch']);

const isClick = ref(true);

const onClickNavIcon = () => {
  hideNavBar.value = !hideNavBar.value;
};

const showMenu = ref(false);
const isMobile = ref(false);
const hideNavBar = ref(props.initialExpand);

let animatingNavMenu = false;

// Function to calculate side margin
const calculateSideMargin = () => {
  const viewportWidth = window.innerWidth;
  const fixedWidth = 1280;
  return (viewportWidth - fixedWidth) / 2;
};

// Reactive side margin
const sideMargin = ref(calculateSideMargin());

// Function to update side margin
const updateSideMargin = () => {
  isMobile.value = window.innerWidth < 1024;
  sideMargin.value = calculateSideMargin();
  const headerContainer = document.querySelector('.header-container.shrink .sub-container');
  if (headerContainer) {
    (headerContainer as any).style.paddingRight = `${sideMargin.value}px`;
  }
};

// Event handler for window resize
const handleResize = () => {
  updateSideMargin();
};

const handleScroll = () => {
  //desktop
  if (window.scrollY < 10) {
    hideNavBar.value = false;
  }else{
    if (!animatingNavMenu && !hideNavBar.value) {
      animatingNavMenu = true;
      for (let i = 1; i < 6; i++) {
        const obj = document.getElementById(`nav-menu-${i}`);
        if (obj){
          gsap.fromTo(obj,{
            opacity: 1,
            x: 0,
          },
          {
            opacity: 0,
            x: 500 - (100 * i),
            ease: 'power2.in',
            duration: 0.4,
            onComplete: () => {
              if (i === 5) {
                animatingNavMenu = false;
                hideNavBar.value = true;
              }
            }
          }
          );
        }
      }
    }
  }
};

onMounted(() => {
  updateSideMargin();
  watch(() => hideNavBar.value, () => {
    if (!hideNavBar.value) {
      for (let i = 1; i < 6; i++) {
        const obj = document.getElementById(`nav-menu-${i}`);
        if (obj){
          gsap.fromTo(obj,{
            opacity: 0,
            x: 500 - (100 * i),
          },
          {
            opacity: 1,
            x: 0,
            ease: 'power2.out',
            duration: 0.4,
          }
          );
        }
      }
    }
  });
  handleScroll();
  window.addEventListener('scroll', handleScroll);
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
  window.removeEventListener('resize', handleResize);
});

const getInTouch = () => {
  showMenu.value = false;
  emit('getInTouch', {});
};

</script>

<style scoped>

:root {
    --side-margin: calc((100vw - 1280px) / 2);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@keyframes slideFadeIn {
  from {
    opacity: 0;
    /* transform: translateX(720px); */
  }
  to {
    opacity: 1;
    /* transform: translateX(0); */
  }
}

@keyframes slideFadeOut {
  from {
    opacity: 1;
    /* transform: translateX(0); */
  }
  to {
    opacity: 0;
    /* transform: translateX(720px); */
  }
}

@keyframes slideNavFadeIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideNavFadeOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes bounceUp {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.header-container {
  display: flex;
  position: fixed;
  align-items: center;
  background-color: transparent;
  justify-content: center;
  max-height: 120px;
  height: 100%;
  width: 100%;
  max-width: 3840px;
  z-index: 999;
  transition: background-color 0.2s ease-in-out 0.2s;
  padding: 0;
  pointer-events: auto;
}

.header-container.shrink{
  width: auto;
  right: 0;
}

.sub-container{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  max-width: 1280px;
  width: 100%;
  margin: 0 48px;
}

.header-container.shrink .sub-container{
  margin: 0;
}

.header-container.light {
  display: flex;
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  transition: background-color 0.2s ease-in-out;
  cursor: default;
}

.blurred-background {
  backdrop-filter: blur(8px);
  border-radius: 12px;
}

.safari-background {
  backdrop-filter: none;
}

.header-container .logo {
  flex-grow: 1;
}

.header-container .logo img {
    height: 32px;
}

.nav-menu ul {
  padding: 0;
  list-style: none;
  display: table;
  text-align: center;
}
.nav-menu ul li {
  display: table-cell;
  position: relative;
  padding: 10px 32px;
}
.nav-menu a {
  color: #000;
  font-size: 20px;
  text-decoration: none;
  z-index: 999;

  display: inline-block;
  padding: 4px 0;
  position: relative;
}

.nav-menu a:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #000;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.nav-menu a.active:after {
  width: 100%;
  left: 0;
}

.nav-menu a:hover:after {
  width: 100%;
  left: 0;
}

.header-nav-logo {
  animation: slideFadeOut 0.3s ease-out forwards;
}

.header-nav-logo.light{
  animation: slideFadeIn 0.3s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
}

.header-nav-icon {
  cursor: pointer;
  /* background-color: rgb(255,255,255,0.2); */
  mix-blend-mode: difference;
  animation: bounceUp 0.3s ease-in-out forwards;
  transition: transform 0.3s ease;
  transition-delay: 0.3s;
}

/* .header-container nav a:hover {
    text-decoration: underline;
    text-underline-offset: 12px;
} */

@media screen and (max-width: 1024px) {

  .mobile-menu-container{
    position: fixed;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 999;
    justify-content: center;
  }
  .mobile-menu-container a{
    color: var(--Neutrals-Nero, #242424);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
  }

  .menu-header-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;
    height: 68px;
    width: 100%;
  }

  .header-container{
    justify-content: flex-end;
    padding: 12px 24px;
    height: 56px;
  }

  .header-nav-logo,
  .header-nav-logo-mobile{
    max-width: 121.5px;
    height: auto;
  }

  .header-nav-icon,
  .header-nav-icon-mobile{
    width: 32px;
    height: 32px;
    margin: 0;
  }

  .menu-list-section{
    display: flex;
    justify-content: center;
    gap: 24px;
    flex-direction: column;
    flex-grow: 1;
  }

  .nav-footer{
    padding-bottom: 24px;
  }

  .nav-footer p,
  .nav-footer a{
    margin: 0 6px;
    align-self: stretch;
    text-align: center;
    color: var(--Neutrals-Nobel, #969696);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  .mobile-menu-container a.active,
  .header-container nav a.active{
    text-decoration: underline;
    text-underline-offset: 12px;
  }
}

@media screen and (max-width: 820px) {
  .sub-container{
    margin: 0;
  }
}
</style>
