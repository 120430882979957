<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: Project Page

Description:
This component displays the project showcase section of the Project page. It includes a grid of project tiles
that can be scrolled through and a click event to open the selected project.

How to use it:
<ProjectShowcase
  :left-item="leftItem"
  :right-item="rightItem"
/>
-->
<template>
  <div
    :class="`container ${!darkMode ? 'light-background' : ''}`"
  >
    <div class="sub-container">
      <div class="column left-item">
        <div
          v-for="(item, index) in leftItem"
          :id="item.slug"
          :key="index"
          :enter="{
            scale: 2,
            transition: {
              delay: 1000,
            },
          }"
          class="grid-item"
        ><a :href="`/work/${ item.slug }`">
          <div class="image-container">
            <img
              :src="item.image.sizes.large"
              :alt="item.image.alt"
              loading="lazy"
            >
          </div>
          <p :class="`title ${!darkMode ? 'light-background' : ''}`">
            {{ item.title }}
          </p>
          <p :class="`subtitle ${!darkMode ? 'light-background' : ''}`">
            {{ item.description }}
          </p>
        </a>
        </div>
      </div>
      <div class="column right-item">
        <div
          v-for="(item, index) in rightItem"
          :id="item.slug"
          :key="index"
          :enter="{
            scale: 2,
            transition: {
              delay: 1000,
            },
          }"
          class="grid-item"
        >
          <a :href="`/work/${ item.slug }`">
            <div class="image-container">
              <img
                :src="item.image.sizes.large"
                :alt="item.image.alt"
                loading="lazy"
              >
            </div>
            <p :class="`title ${!darkMode ? 'light-background' : ''}`">
              {{ item.title }}
            </p>
            <p :class="`subtitle ${!darkMode ? 'light-background' : ''}`">
              {{ item.description }}
            </p>
          </a>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup lang="ts">

import { onMounted } from 'vue';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const emit = defineEmits(['end-animation']);

const props = defineProps({
  darkMode: {
    type: Boolean,
    default: false,
  },
  gridItems: {
    type: Array,
    default: () => {
      return [];
    }
  }});

const leftItem: any[] = [];
const rightItem: any[] = [];

props.gridItems.forEach((item: any, index) => {
  if (index % 2 === 0) {
    // Even index, insert into leftItem
    leftItem.push(item);
  } else {
    // Odd index, insert into rightItem
    rightItem.push(item);
  }
});

onMounted(() => {
  props.gridItems.forEach((item: any) => {
    const selector = document.getElementById(item.slug);
    gsap.fromTo(selector, {
      opacity: 0,
    }, {
      opacity: 1,
      scrollTrigger: {
        trigger: selector,
        start: 'top 75%',
        toggleActions: 'play none none none',
        onEnter: () => {
          emit('end-animation');
        }
      }
    });
  });
});

</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  padding-top: 160px;
  padding-bottom: 0px;
  transition: opacity 0.5s ease;
  background-color: black;
}

.container.light-background {
  background-color: white;
}

.sub-container {
  max-width: 1280px;
  margin: 0px 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.column {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.left-item{
  margin-right: 40px;
}
.right-item {
  margin-top: 400px;
  margin-left: 40px;
}

.title{
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: white;
  padding-top: 40px;
  padding-bottom: 16px;
}

.title.light-background{
  color: #969696;
}

.grid-item {
  overflow: hidden; /* Prevents the container from resizing */
  transition: transform 0.3s ease; /* Optional: Smooth transition for the container */
}

.image-container{
  overflow: hidden;
}

.grid-item img {
  width: 100%;
  transition: transform 0.3s ease; /* Smooth transition for zoom effect */
  display: block; /* Ensures the image does not have extra space around it */
}

.grid-item img:hover{
  transform: scale(1.05);
}

.subtitle{
  font-family: 'Inter', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.25px;
  color: #969696;
  padding-bottom: 160px;
}

.subtitle.light-background{
  color: #242424;
}

@media screen and (max-width: 820px) {
  .sub-container {
    margin: 0px 80px;
  }

  .left-item{
    margin-right: 32px;
  }
  .right-item {
    margin-top: 109px;
    margin-left: 32px;
  }
}

@media screen and (max-width: 480px) {
  .container {
    padding-top: 80px;
  }

  .title{
    color: var(--Neutrals-White, #FFF);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    padding-top: 24px;
    padding-bottom: 8px;
  }
  .subtitle{
    color: var(--Neutrals-Nobel, #969696);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
    padding-bottom: 80px;
  }

  .sub-container {
    margin: 0px 24px;
  }

  .left-item{
    margin-right: 8px;
  }
  .right-item {
    margin-top: 109px;
    margin-left: 8px;
  }
}

</style>

