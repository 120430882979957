<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: Contact Us Form
Description:
This component represents the fourth page of the multi-step "Contact Us" form, where users specify their
project budget and ideal go-live date. It includes a budget selection and a date picker for easy user input.
Additionally, it includes a fun hidden feature that reveals a video if the user selects an unrealistic go-live
date far into the future. Users can proceed to the next step once they provide valid inputs.

How to use it:
<ContactUsPageFour
  :project-budget="projectBudget"
  :project-date="projectDate"
  @submit="handlePageFourSubmit"
/>
Pass `projectBudget` to pre-select a budget category and `projectDate` for an initial go-live date.
The component emits a `submit` event with the selected data when the user clicks "Next."
-->
<template>
  <div
    class="page-four-container"
  >
    <h2 class="form-title">
      What is the budget for your project?
    </h2>
    <form
      class="contact-form"
      @submit.prevent="submitForm"
    >
      <h3>
        Please select one:
      </h3>
      <div class="form-group-selection-container">
        <div
          v-for="budget in budgets"
          :key="budget.category"
          :class="`form-group-selection ${selectedbudget === budget.category ? 'selected':''}`"
          @click="onSelectbudget(budget.category)"
        >
          <div>
            <p>{{ budget.category }}</p>
            <h4> {{ budget.value }}</h4>
          </div>
        </div>
      </div>
      <div class="form-group">
        <h3> What is your ideal go-live date?</h3>
        <div class="date-picker">
          <Dropdown
            ref="datepickerRef"
            :manual="true"
          >
            <template #trigger>
              <div style="display: flex; justify-content: space-between; position: relative; width: 100%;">
                <input
                  id="other"
                  v-model="dateString"
                  type="text"
                  placeholder="Please select date"
                  @click="openDatePicker"
                >
                <img
                  class="custom-icon"
                  style="position: absolute; right: 0; top: 50%; transform: translateY(-50%); padding-right: 24px;"
                  :src="$cdn('icons/icon_calendar.svg')"
                  @click="openDatePicker"
                >
              </div>
            </template>
            <div style="display: flex; flex-direction: column;">
              <DatePicker
                v-model="date"
                :min-date="minDate"
                style="border: none;"
              />
              <span
                style="cursor: pointer; padding: 8px;"
                @click="clearDate"
                @mousedown.prevent
              >Clear
              </span>
            </div>

          </Dropdown>
        </div>
      </div>
      <div
        class="rickroll-video"
        :class="{'you-just-got-rickrolled': youJustGotRickrolled}"
      >
        <iframe
          :width="`${isMobile ? '300' : '560'}`"
          :height="`${isMobile ? '100%' : '315'}`"
          src="https://www.youtube.com/embed/dQw4w9WgXcQ?si=tPKJZNCfrpAfD2aT&controls=0"
          title="Never Give You Up"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen="false"
        />
      </div>

      <div class="button-container">
        <button
          type="submit"
          :class="`submit-button ${!isFormValid ? 'disabled' : ''}`"
          :disabled="!isFormValid"
        >
          Next
        </button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue';
import { DatePicker } from 'v-calendar';
import 'v-calendar/style.css';
import { format } from 'date-fns';
import Dropdown from 'v-dropdown';

const props = defineProps({
  projectBudget:{
    type: String,
    default: ''
  },
  projectDate:{
    type: String,
    default: undefined
  }
});

const youJustGotRickrolled = ref(false);

onMounted(()=>{
  if (props.projectBudget.length > 0) {
    selectedbudget.value = props.projectBudget;
  }
  if (props.projectDate) {
    date.value = props.projectDate;
  }

  watch(date, (newDate, oldDate) => {
    if (newDate) {
      setTimeout(() => {
        (datepickerRef.value as any).close();
      }, 200);
      const milliDiff = new Date(newDate).getTime() - new Date().getTime();
      const totalSeconds = Math.floor(milliDiff / 1000);
      const totalMinutes = Math.floor(totalSeconds / 60);
      const totalHours = Math.floor(totalMinutes / 60);
      if (totalHours > 87600) {
        youJustGotRickrolled.value = true;
      }else{
        youJustGotRickrolled.value = false;
      }
    }else{
      youJustGotRickrolled.value = false;
    }
  });
});

const emit = defineEmits(['submit']);

const datepickerRef = ref(null);

function openDatePicker(){
  (datepickerRef.value as any).display();
}

const dateString = computed(() => {
  return date.value ? format(new Date(date.value), 'dd/MM/yyyy') : 'Please select date';
});

const minDate = new Date().toISOString().split('T')[0];

function clearDate() {
  date.value = null;
}

const isMobile = computed(() => {
  return window.innerWidth < 768;
});

const date = ref();

const budgets = ref([
  { value: 'US$ 10,000 - US$ 20,000' , category: 'Small' },
  { value: 'US$ 20,000 - US$ 50,000' , category: 'Medium' },
  { value: 'US$ 50,000 - US$ 100,000' , category: 'Large' },
  { value: 'US$ 100,000 or more' , category: 'Enterprise' },
]);

const onSelectbudget = (budget: string) => {
  if (selectedbudget.value === budget) {
    selectedbudget.value = '';
  }else {
    selectedbudget.value = budget;
  }
};

const selectedbudget = ref('');

const isFormValid = computed(() => {
  return selectedbudget.value !== '' && date.value;
});
const submitForm = () => {
  if (selectedbudget.value === '') {
    alert('Please specify your budget.');
    return;
  }
  const formData = {
    project_budget: selectedbudget.value,
    project_date: date.value,
  };
  emit('submit', formData);
};

</script>

<style scoped>

.page-four-container{
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.page-four-container::-webkit-scrollbar {
  display: none;
}

.contact-form .user-information{
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 48px;
}

h3 {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 24px;
}

.form-title {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.5px;
  margin-bottom: 64px;
}

.form-group-selection-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-bottom: 64px;
}

.form-group-selection {
  background-color: #fff;
  border: 1px solid var(--Neutrals-Gainsboro, #DCDCDC);
  padding: 16px 24px;
  margin: 0 3px;
  border-radius: 12px;
  cursor: pointer;
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  transition: background-color 0.3s, color 0.3s;
}

.form-group-selection p{
  color: #969696;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.25px;
}

.form-group input {
  width: 50%;
  margin: 0 3px;
  height: 64px;
  border-radius: 12px;
  border: 1px solid var(--Neutrals-Gainsboro, #DCDCDC);
  background-color: #FFF;
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  padding: 16px 24px;
}

.form-group-selection h3{
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.form-group-selection p,
.form-group-selection h3 {
  transition: color 0.3s; /* Add transition for smooth color change */
}

.form-group-selection.selected {
  border: 1px solid var(--Neutrals-Gainsboro, transparent);;
  outline: 2px solid var(--Neutrals-Gainsboro, #007aff);
}

.date-picker{
  width: 50%;
  height: 64px;
  border-radius: 12px;
  margin: 0 3px;
  padding-right: 16px;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
}

.date-picker input {
  width: 100%;
}

.date-picker input:focus {
  outline: none !important;
  border: 1px solid var(--Neutrals-Gainsboro, #DCDCDC);
}

.file-picker-container{
  display: flex;
  flex-direction: row;
}

.file-picker-container p{
  flex-grow: 1;
}

.date-picker input[type="date"] {
  border: none;
  width: 100%;
  background-color: white;
  cursor: pointer; /* Improve UX by indicating the input is clickable */
}

.custom-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.date-picker-object{
  display: none;
}

.calendar-icon{
  padding: 0 12px;
}

.submit-button {
  color: #FFF;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 64px 0;
  width: 93px;
  height: 64px;
  padding: 16px 24px;
  border: none;
  cursor: pointer;
  border-radius: 12px;
  background-color: #007AFF;
  transition: all 0.3s ease;
}

.submit-button:hover {
  opacity: 0.8;
}

.submit-button.disabled {
  color: #969696;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  background-color: transparent;
  border-radius: 12px;
  border: 1px solid var(--Neutrals-Gainsboro, #DCDCDC);
  cursor: not-allowed;
}

.rickroll-video{
  display: none;
  padding: 32px 0;
}

.rickroll-video.you-just-got-rickrolled {
  display: block;
}

@media screen and (max-width: 768px) {
  .form-title {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 40px;
  }

  .contact-form{
    width: 100%;
  }

  h3 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .form-group-selection-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 32px;
  }

  .form-group-selection{
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    border-radius: 10px;
  }

  .contact-form .user-information{
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 32px;
  }

  .form-group label {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
    margin-bottom: 12px;
  }

  .form-group input,
  .form-group select {
    width: 100%;
    height: 48px;
    border-radius: 10px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .date-picker{
    width: calc(100% - 6px);
    padding: 0;
  }

  .button-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 80px;
    width: 100%;
  }

  .submit-button {
    width: 84px;
    height: 48px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    border-radius: 10px;
  }

  .submit-button.disabled {
    width: 84px;
    height: 48px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    border-radius: 10px;
  }

  .rickroll-video.you-just-got-rickrolled {
    display: flex;
    justify-content: center;
  }
}

</style>
