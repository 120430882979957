<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: General Components
Description:
This component provides a placeholder to display testimonials, with error handling and loading states.
It includes different placeholders for loading, errors, or if content data is not available.
The content can be set to autoplay and loop as per the provided props.

How to use it:
<Testimonial :data="testimonialData" />
-->
<template>
  <section class="main-container">
    <div
      v-if="testimonials.length > 0"
      ref="target"
      class="testimonial-container"
    >
      <section class="sub-container">
        <swiper
          :pagination="true"
          :autoplay="{
            delay: 5000,
            disableOnInteraction: false,
          }"
          :slides-per-view="1"
          :speed="300"
        >
          <swiper-slide
            v-for="(testimonial, index) in testimonials"
            :key="index"
            class="testimonial-content"
          >
            <div class="testimonial-wrapper">
              <blockquote class="testimonial-text">
                <span
                  v-for="( text, i) in testimonial.text"
                  :key="i"
                >
                  {{ text }}
                </span>

              </blockquote>
            </div>
            <div class="testimonial-author">
              <img
                class="author-image"
                :src="testimonial.image"
                :alt="testimonial.name"
              >
              <div class="author-details">
                <div class="author-name">
                  {{ testimonial.name }}
                </div>
                <div class="author-position">
                  {{ testimonial.position }}
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </section>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore from 'swiper';
import { Autoplay, FreeMode, Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  }
});

type Testimonial = {
  text: string[];
  name: string;
  position: string;
  image: string;
}

const testimonials = ref<Array<Testimonial>>([]);
const target = ref<any>(null);

onMounted(() => {
  testimonials.value = props.data.testimonies.map((value: any) => {
    return {
      text: value.testimony.messages !== false ? value.testimony.messages.map((value: any) => value.message) : '',
      name: value.testimony.name,
      position: value.testimony.job_position,
      image: value.testimony.avatar,
    };
  });

  watch(target, (value) => {
    if (value) {
      gsap.fromTo(value, {
        opacity: 0,
      }, {
        opacity: 1,
        scrollTrigger: {
          trigger: value,
          start: 'top 75%',
          toggleActions: 'play none none none',
        }
      });
    }
  }, { immediate: true });
});

SwiperCore.use([Autoplay, FreeMode, Navigation, Pagination]);
</script>

<style scoped>

.main-container{
  display: flex;
  justify-content: center;
  background-color: #FAFAFA;
  padding-top: 64px;
  padding-bottom: 32px;
}

.testimonial-container {
  background-color: transparent;
  display: flex;
  justify-content: center;
  padding: 0 32px;
}

.sub-container {
  max-width: 1000px;
  width: 100%;
  padding: 0 24px;
}

.testimonial-wrapper {
  display: flex;
  align-items: center;
}

.testimonial-text {
  position: relative;
  padding-left: 32px;
  font-style: italic;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.testimonial-text::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  width: 2px;
  background-color: #00CEB0;
}

.testimonial-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.testimonial-text {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-align: start;
}

.testimonial-author {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-top: 40px;
  padding-bottom: 80px;
  padding-left: 32px;
}

.author-image {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.author-details {
  text-align: left;
}

.author-name {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  line-height: 32px;
  font-weight: 400;
  color: #242424;
}

.author-position {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  color: #969696;
}

.dots {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot {
  height: 8px; /* Size of dots */
  width: 8px; /* Size of dots */
  margin: 0 5px; /* Spacing between dots */
  background-color: #bbb; /* Color of dots */
  border-radius: 50%; /* Makes dots circular */
  display: inline-block;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: #000; /* Color of the active dot */
}

@media screen and (max-width: 820px) {

  .main-container{
    overflow-x: hidden;
  }

  .sub-container {
    width: 100vw;
    padding: 0 80px;
  }

  .testimonial-text {
    color: var(--Neutrals-Nobel, #969696);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.25px;
    padding-left: 24px;
    gap: 24px;
  }

  .author-image {
    width: 40px;
    height: 40px;
  }

  .author-name {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  .author-position {
    color: var(--Neutrals-Nobel, #969696);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

}

@media screen and (max-width: 480px) {
  .sub-container {
    padding: 0 24px;
  }
}

</style>
