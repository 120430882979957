<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: Blog Page
Description:
This component displays the header of a blog post, including the estimated reading time, categories, main title, subtitle,
and an optional header image. Categories are dynamically formatted as links, allowing users to explore related content.
It creates an inviting and informative introduction for each blog entry.

How to use it:
<BlogHeader
  :estimateReadingTime="'5 min read'"
  :title="'Blog Post Title'"
  :subTitle="'A short description of the blog post.'"
  :headerImage="{ url: 'path/to/image.jpg', alt: 'Header Image' }"
  :category="categoriesArray"
/>
Pass props such as `estimateReadingTime`, `title`, `subTitle`, `headerImage`, and `category` to provide details for the blog post header.
-->

<template>
  <div class="main-container">
    <div class="sub-container">
      <div style="display: flex; align-items: center; gap: 8px;">
        <p>{{ estimateReadingTime }} • Filed under: </p>
        <div
          class="category-link"
          v-html="formattedCategories"
        />
      </div>
      <h1>{{ title }}</h1>
      <h3>{{ subTitle }}</h3>

    </div>
    <img
      v-if="headerImage?.url"
      :src="headerImage?.url"
      :alt="headerImage?.alt"
    >
  </div>
</template>

<script setup lang="ts">

import { computed } from 'vue';

interface Category {
  name: string,
  slug: string
}

const props = defineProps({
  estimateReadingTime: {
    type: String,
    default: '7 min read'
  },
  title: {
    type: String,
    default: ''
  },
  subTitle: {
    type: String,
    default: ''
  },
  headerImage: {
    type: Object,
    default: undefined
  },
  category: {
    type: Array as () => Category[],
    default: undefined
  }
});

const baseUrl = process.env.APP_URL;

const formattedCategories = computed(() => {
  if (!props.category) return 'Undefined';
  return props.category
    .map((obj) => `<a href="${baseUrl}/blog?tag=${obj.slug}" class="category-link">${obj.name ?? 'Undefined'}</a>`)
    .join(' | ');
});

</script>

<style scoped>

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sub-container {
  display: flex;
  flex-direction: column;
  max-width: 1120px;
  width: 100%;
  padding: 0 48px;
}

h1 {
  color: #242424;
  font-family: 'Inter' sans-serif;
  font-size: 64px;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: -3px;
  text-align: left;
  padding: 16px 0;
}

h3 {
  color: #969696;
  font-family: 'Inter' sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
}

p {
  color: #242424;
  font-family: 'Inter' sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.25px;
  text-align: left;
}

.category-link:deep(a) {
  color: #007AFF;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.25px;
}

.main-container img {
  object-fit: cover;
  width: 100%;
  margin: 80px 0;
  aspect-ratio: 12/5;
}

@media screen and (max-width: 820px) {
  .sub-container {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0 24px;
  }

  h1 {
    color: #242424;
    font-family: 'Inter' sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -1px;
    text-align: left;
  }

  h3 {
    font-family: 'Inter' sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: left;
  }

  p {
    color: #242424;
    font-family: 'Inter' sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }

  .category-link:deep(a) {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .main-container img {
    object-fit: cover;
    height: 260px;
    width: 100%;
    margin: 64px 0;
    aspect-ratio: 0;
  }
}

</style>
