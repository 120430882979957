<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: Contact Us Form
Description:
This component represents the second page of the multi-step "Contact Us" form, where users specify the purpose of their inquiry.
Users can select a predefined reason or specify their own. The form ensures that a valid purpose is provided before proceeding to the next step.

How to use it:
<ContactUsPageTwo
  :contact-purpose="selectedPurpose"
  @submit="handlePageTwoSubmit"
/>
Pass `contactPurpose` as a string to pre-select the relevant inquiry reason. The component emits a `submit` event when the user clicks "Next," containing the purpose of the inquiry.
-->
<template>
  <div class="page-two-container">
    <h2 class="form-title">
      How can we help you?
    </h2>
    <form
      class="contact-form"
      @submit.prevent="submitForm"
    >
      <p>Please select one:</p>
      <div class="form-group-selection-container">
        <div
          v-for="reason in reasons"
          :key="reason"
          :class="`form-group-selection ${selectedReason === reason ? 'selected':''}`"
          @click="onSelectReason(reason)"
        >
          {{ reason }}
        </div>
      </div>
      <div
        class="form-group"
        style="display: flex; justify-content: center"
      >
        <input
          id="other"
          v-model="otherText"
          type="text"
          placeholder="Others (please specify)"
          :class="selectedReason === 'other' ? 'other-input':''"
          :style="selectedReason === 'other' ? 'border: 1px solid var(--Neutrals-Gainsboro, transparent); outline: 2px solid var(--Neutrals-Gainsboro, #007aff);':'border: 1px solid #DCDCDC;'"
          @input="onOtherInputChange"
          @focusin="onOtherInputChange"
        >
      </div>
      <div class="button-container">
        <button
          type="submit"
          :class="`submit-button ${!isFormValid ? 'disabled' : ''}`"
          :disabled="!isFormValid"
        >
          Next
        </button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';

type Props = {
  contactPurpose: string
}

const props = defineProps<Props>();

const emit = defineEmits(['submit']);

onMounted(() => {
  if (props.contactPurpose.length > 0) {
    if (reasons.value.includes(props.contactPurpose)) {
      selectedReason.value = props.contactPurpose;
    }else{
      otherText.value = props.contactPurpose;
      selectedReason.value = 'other';
    }
  }
});

const reasons = ref([
  'I’d like a cost estimate',
  'I have a confirmed project that I’d like built',
  'I want to enhance my existing system',
  'I need you to maintain my existing system',
]);

const onSelectReason = (reason: string) => {
  if (selectedReason.value === reason) {
    selectedReason.value = '';
  }else {
    selectedReason.value = reason;
  }
  otherText.value = '';
};

const selectedReason = ref('');
const otherText = ref('');

const onOtherInputChange = () => {
  if (otherText.value.trim() !== '') {
    selectedReason.value = 'other';
  }else{
    selectedReason.value = '';
  }
};

const isFormValid = computed(() => {
  return (otherText.value.trim() !== '' && selectedReason.value !== 'other') || selectedReason.value !== '';
});

const submitForm = () => {
  if (selectedReason.value === 'other' && otherText.value.trim() === '') {
    alert('Please specify in the "Others" field.');
    return;
  }

  const formData = {
    contact_purpose: selectedReason.value === 'other' ? otherText.value : selectedReason.value,
  };

  emit('submit', formData);
};
</script>

<style scoped>

.page-two-container {
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.page-two-container::-webkit-scrollbar {
  display: none;
}

.contact-form .user-information{
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 48px;
}

.contact-form p {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 24px;
}

.form-title {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.5px;
  margin-bottom: 64px;
}

.form-group-selection-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-bottom: 16px;
}

.form-group-selection {
  background-color: #fff;
  border: 1px solid var(--Neutrals-Gainsboro, #DCDCDC);
  padding: 16px 24px;
  margin: 0 3px;
  border-radius: 12px;
  cursor: pointer;
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  transition: color 0.3s;
}

.form-group input {
  width: calc(100% - 6px);
  height: 64px;
  border-radius: 12px;
  padding: 16px 24px;
  border: 1px solid var(--Neutrals-Gainsboro, #DCDCDC);
  background-color: #FFF;
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  box-sizing: border-box;
}


.form-group-selection.selected,
.form-group input:focus {
  border: 1px solid var(--Neutrals-Gainsboro, transparent);
  outline: 2px solid var(--Neutrals-Gainsboro, #007aff);
}

.submit-button {
  color: #FFF;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 64px 0;
  width: 93px;
  height: 64px;
  padding: 16px 24px;
  border: none;
  cursor: pointer;
  border-radius: 12px;
  background-color: #007AFF;
  transition: all 0.3s ease;
}

.submit-button:hover {
  opacity: 0.8;
}

.submit-button.disabled {
  color: #969696;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  background-color: transparent;
  border-radius: 12px;
  border: 1px solid var(--Neutrals-Gainsboro, #DCDCDC);
  cursor: not-allowed;
}

@media screen and (min-width: 1080px) {
  .form-group-selection{
    height: 64px;
  }
}

@media screen and (max-width: 768px) {

  .form-title {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 40px;
  }

  .contact-form{
    width: 100%;
  }

  .contact-form p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .form-group-selection-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
  }

  .form-group-selection{
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    padding: 12px 24px;
    border-radius: 10px;
  }

  .contact-form .user-information{
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 32px;
  }

  .form-group label {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
    margin-bottom: 12px;
  }

  .form-group input {
    height: 48px;
    border-radius: 10px;
    padding: 12px 24px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .button-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 80px;
    width: 100%;
  }

  .submit-button {
    width: 84px;
    height: 48px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    border-radius: 10px;
  }

  .submit-button.disabled {
    width: 84px;
    height: 48px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    border-radius: 10px;
  }
}

</style>
